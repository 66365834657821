import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "w-11/12 flex flex-col items-start mx-auto my-4" }
const _hoisted_3 = { class: "w-full flex items-center justify-between gap-2" }
const _hoisted_4 = { class: "w-full flex items-center justify-start gap-2 mx-auto my-2" }
const _hoisted_5 = { class: "text-[#98A2B3] text-sm font-semibold" }
const _hoisted_6 = { class: "text-[#98A2B3] text-sm font-semibold" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "w-11/12" }
const _hoisted_9 = { class: "font-medium text-sm text-[#101828] mb-2 line-clamp-3" }
const _hoisted_10 = { class: "text-xs text-[#98A2B3]" }
const _hoisted_11 = {
  key: 0,
  class: "font-bold text-xs text-orange-300"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "flex items-start relative z-10" }
const _hoisted_14 = { class: "w-4 h-4 border border-gray-300 rounded-full flex-shrink-0 mt-1 flex items-center justify-center -top-12" }
const _hoisted_15 = { class: "ml-4 w-full flex items-center justify-between" }
const _hoisted_16 = { class: "w-full flex flex-col items-start" }
const _hoisted_17 = { class: "flex items-center justify-between mt-1" }
const _hoisted_18 = { class: "text-gray-400 text-xs" }
const _hoisted_19 = { class: "uppercase" }
const _hoisted_20 = {
  key: 0,
  class: "font-bold text-xs text-orange-300"
}
const _hoisted_21 = {
  key: 1,
  class: "font-bold text-red-300"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "flex items-start relative z-10" }
const _hoisted_24 = { class: "w-4 h-4 border border-gray-300 rounded-full flex-shrink-0 mt-1 flex items-center justify-center" }
const _hoisted_25 = { class: "ml-4 w-full flex items center justify-between" }
const _hoisted_26 = { class: "w-full flex flex-col items-start" }
const _hoisted_27 = { class: "flex items-center justify-between mt-1" }
const _hoisted_28 = { class: "text-gray-400 text-xs" }
const _hoisted_29 = { class: "uppercase" }
const _hoisted_30 = {
  key: 0,
  class: "font-bold text-red-300"
}

import CategoryTreeNode from "@/models/CategoryTreeNode";
import {
  ChevronUpIcon,
  ClockStopwatchIcon,
  Key01Icon,
  Lock03Icon,
} from "@gohighlevel/ghl-icons/24/outline";

import { NODE_TYPES } from "@/helper/constants";
import { PostContentType, PostVisibility } from "@/models/Post";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { PostService } from "@/services";
import CategorySyllabusShimmerV2 from "../shimmer/mobile/CategorySyllabusShimmerV2.vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategorySyllabusV2',
  props: {
    categoryTree: {},
    allCompletedPostsData: {},
    source: {},
    totalPosts: {},
    productId: {},
    hasProductAccess: { type: Boolean, default: true }
  },
  setup(__props: any) {

const router = useRouter();
const route = useRoute();
const store = useStore();



const categoryExpandedMap = ref<{ [key: string]: boolean }>({});
const expandAll = ref(false);
const categoryAccordianRef = ref();
const categoryLessonCountMap = ref<Map<string, number>>();
const isComponentCalledFromBundle = computed(() => __props.source === "BUNDLE");
const fetching = ref(false);
const postLockMap = ref<{
  postUnlockedMap: { [key: string]: boolean };
  categoryUnlockedMap: { [key: string]: boolean };
}>({ postUnlockedMap: {}, categoryUnlockedMap: {} });

const isLevelUpItem = computed(() => {
  return store.state.featureFlags.newMobileUi
});

// Function to generate class object based on lesson and category
const getLessonClass = (lesson, category) => {
  if (!lesson) return {};
  return {
    "my-4": lesson.type === NODE_TYPES.POST,
    "my-8": lesson.type !== NODE_TYPES.POST,
  };
};

// Function to generate class object based on sub and category
const getSubClass = (sub) => {
  if (!sub) return {};
  return {
    "my-2": sub.type === NODE_TYPES.POST,
    "mt-8 mb-2": sub.type !== NODE_TYPES.POST,
  };
};
const getLessonCategoryDisabledClass = (category, sub, lesson) => {
  return {
    "text-gray-800": category.node.visibility !== PostVisibility.drip && sub?.node?.visibility !== PostVisibility.drip && lesson?.node?.visibility !== PostVisibility.drip,
    "text-gray-400": category.node.visibility === PostVisibility.drip || sub?.node?.visibility === PostVisibility.drip || lesson?.node?.visibility === PostVisibility.drip,
  };
};

// Helper function to check if all posts under a node are completed
const areAllPostsCompleted = (node) => {
  if (Array.isArray(node?.posts) && node?.posts.length) {
    const postIds = node.posts.map((post) => post?.id);
    return postIds.every((id) => __props.allCompletedPostsData?.completedPostIds?.has(id));
  }
  return false;
};

function getBackgroundClassForLessonPointer(
  category,
  sub,
  lesson,
  allCompletedPostsData
) {
  let allIdsCompleted = false;
  const isPostOrCatUnlocked =
    postLockMap.value.postUnlockedMap[category?.id] ||
    postLockMap.value.categoryUnlockedMap[category?.id] ||
    postLockMap.value.postUnlockedMap[sub?.id] ||
    postLockMap.value.categoryUnlockedMap[sub?.id] ||
    postLockMap.value.postUnlockedMap[lesson?.id] ||
    postLockMap.value.categoryUnlockedMap[lesson?.id];
  const isDripLocked =
    sub?.node?.visibility === PostVisibility.drip ||
    category?.node?.visibility === PostVisibility.drip ||
    lesson?.node?.visibility === PostVisibility.drip;
  const isVisibilityLocked =
    sub?.node?.visibility === PostVisibility.locked ||
    category?.node?.visibility === PostVisibility.locked ||
    lesson?.node?.visibility === PostVisibility.locked;

  // Check if all posts under the lesson are completed
  if (lesson?.node) {
    allIdsCompleted = areAllPostsCompleted(lesson.node);
  }

  // Check if all posts under the sub-category are completed
  if (sub?.node) {
    allIdsCompleted = areAllPostsCompleted(sub.node);
  }

  if (isDripLocked) {
    return { "bg-orange-300": true }; // If it's drip content, it's not locked
  } else if (!isPostOrCatUnlocked && isVisibilityLocked) {
    return { "bg-red-300": true };   // If the post or category is not unlocked and the visibility is locked, return true
  } else if (
    allCompletedPostsData?.completedPostIds?.has(lesson?.node?.id) ||
    allCompletedPostsData?.completedPostIds?.has(sub?.node?.id) ||
    allIdsCompleted
  ) {
    return { "bg-green-300": true }; // GREEN MEANS COMPLETED
  } else {
    return { "bg-transparent": true }
  }
}

function isLocked(category, sub, lesson) {
  const isPostOrCatUnlocked =
    postLockMap.value.postUnlockedMap[sub?.id] ||
    postLockMap.value.categoryUnlockedMap[category?.id] ||
    postLockMap.value.postUnlockedMap[category?.id] ||
    postLockMap.value.categoryUnlockedMap[sub?.id] ||
    postLockMap.value.postUnlockedMap[lesson?.id] ||
    postLockMap.value.categoryUnlockedMap[lesson?.id];

  const isVisibilityLocked =
    sub?.node?.visibility === PostVisibility.locked ||
    category?.node?.visibility === PostVisibility.locked ||
    lesson?.node?.visibility === PostVisibility.locked;

  const isDripLocked =
    sub?.node?.visibility === PostVisibility.drip ||
    category?.node?.visibility === PostVisibility.drip;

  // If it's drip content, it's not locked
  if (isDripLocked) {
    return false;
  }

  // If the post or category is not unlocked and the visibility is locked, return true
  if (!isPostOrCatUnlocked && isVisibilityLocked) {
    return true;
  }

  // Otherwise, it's not locked
  return false;
}

function toggleAccordion(categoryId) {
  categoryExpandedMap.value = {
    ...categoryExpandedMap.value,
    [categoryId]: !categoryExpandedMap.value[categoryId],
  };
  if (categoryExpandedMap.value[categoryId]) {
    scrollToCategory(categoryId);
  }
}

function navigateToLesson(lesson, productId, categoryId, postId) {
  // Determine the route name based on the content type
  let routeName;
  if (lesson?.contentType === PostContentType.assignment) {
    routeName = "mobile-assignment";
  } else if (lesson?.contentType === PostContentType.quiz) {
    routeName = "mobile-quiz-overview";
  } else {
    routeName = "post-overview";
  }

  // Navigate to the determined route
  router.push({
    name: routeName,
    params: {
      id: productId,
      category_id: categoryId,
      post_id: postId,
    },
  });
}

/**
 * Handles the post details navigation based on lesson type and visibility conditions.
 *
 * @param {String} productId - The ID of the product.
 * @param {String} categoryId - The ID of the category.
 * @param {String} postId - The ID of the post.
 * @param {Object} lesson - The lesson object containing information like content type and visibility.
 * @param {Object} category - The category object containing visibility information.
 * @param {String} lessonType - The type of the lesson (e.g., post, quiz, assignment).
 */
function postDetails(productId, categoryId, postId, category, sub, lesson, lessonType) {
  // Check if the component is called from a bundle or if the lesson/category is locked or dripping, or if the lesson type isn't a post.
  if (
    isComponentCalledFromBundle.value ||
    category?.node?.visibility === PostVisibility.drip ||
    sub?.node?.visibility === PostVisibility.drip ||
    lesson?.node?.visibility === PostVisibility.drip ||
    lessonType !== NODE_TYPES.POST ||
    !__props.hasProductAccess
  ) {
    return; // Exit early if any of the conditions are met.
  }

  // Close the curriculum comments section before navigation.
  store.commit("SET_CURRICULUM_COMMENTS_OPEN", false);

  navigateToLesson(lesson.node || sub.node, productId, categoryId, postId);
}

function handleExpandAll() {
  expandAll.value = !expandAll.value;
  __props.categoryTree.forEach((category) => {
    categoryExpandedMap.value = {
      ...categoryExpandedMap.value,
      [category.id]: expandAll.value,
    };
  });
}
function scrollToCategory(categoryId) {
  const activeCategoryIndex = __props.categoryTree.findIndex(
    (category) => category.id === categoryId
  );
  categoryAccordianRef.value.children?.[activeCategoryIndex]?.scrollIntoView({
    behavior: "smooth",
  });
}

/*
 @description: This function is used to get the lesson count per category map.
 @param {CategoryTreeNode[]} treeNodes - The tree nodes.
 @returns {Map<string, number>} - The lesson count per category map.
*/
function getCategoryLessonCountMap(treeNodes: CategoryTreeNode[]): Map<string, number> {
  const categoryLessonCountMap = new Map<string, number>();

  function traverseTree(node: CategoryTreeNode): number {
    let currentCategoryLessonCount = 0;

    // If the node is a POST, increment the count
    if (node.type === NODE_TYPES.POST) {
      currentCategoryLessonCount++;
    }

    // Traverse the children of the current node (if any)
    for (const child of node?.childs || []) {
      currentCategoryLessonCount += traverseTree(child);
    }

    return currentCategoryLessonCount;
  }

  // Only map the root nodes to their total lesson count
  for (const treeNode of treeNodes) {
    const totalLessonCount = traverseTree(treeNode);
    categoryLessonCountMap.set(treeNode.id, totalLessonCount);
  }

  return categoryLessonCountMap;
}


onMounted(async () => {
  const category_id = route.query?.category_id;
  if (category_id) {
    const categoryId = Array.isArray(category_id) ? category_id[0] : category_id;
    categoryExpandedMap.value = {
      ...categoryExpandedMap.value,
      [categoryId]: true,
    };
    scrollToCategory(categoryId);
  }
  categoryLessonCountMap.value = getCategoryLessonCountMap(__props.categoryTree);
  try {
    fetching.value = true;
    postLockMap.value = await PostService.getPostLockMap(__props.productId);
  } catch (error) {
    console.log(error);
  } finally {
    fetching.value = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (fetching.value)
      ? (_openBlock(), _createBlock(CategorySyllabusShimmerV2, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-semibold text-[#101828] font-sans" }, "Curriculum", -1)),
              _withDirectives(_createElementVNode("span", {
                class: "text-xs text-[--accent-color] cursor-pointer clickable",
                onClick: handleExpandAll
              }, _toDisplayString(!expandAll.value
                ? `Expand
            all sections`
                : "Collapse all sections"), 513), [
                [_vShow, _ctx.totalPosts && _ctx.categoryTree?.length]
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.totalPosts) + " " + _toDisplayString(_ctx.totalPosts > 1 ? "Lessons" : "Lesson"), 1),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-[--accent-color] font-bold rounded w-1 h-1" }, null, -1)),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.categoryTree?.length) + " " + _toDisplayString(_ctx.categoryTree?.length > 1 ? "Modules" : "Module"), 1)
            ])
          ]),
          _createElementVNode("div", {
            class: "w-11/12 mx-auto bg-white rounded-xl overflow-hidden mb-8",
            ref_key: "categoryAccordianRef",
            ref: categoryAccordianRef
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTree, (category) => {
              return (_openBlock(), _createElementBlock("div", {
                key: category.id,
                class: "accordion-item rounded-md mt-2"
              }, [
                _createElementVNode("div", {
                  onClick: ($event: any) => (toggleAccordion(category.id)),
                  class: _normalizeClass(["flex items-center justify-between cursor-pointer m-0 p-4 border rounded-xl border-gray-100 text-gray-800 clickable", {
              'bg-gray-100': categoryExpandedMap.value[category.id],
            }])
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(category.node.title), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(categoryLessonCountMap.value?.get(category.id) || 0) + " " + _toDisplayString(categoryLessonCountMap.value?.get(category.id) > 1 ? "Lessons" : "Lesson"), 1),
                    (category.node.visibility === _unref(PostVisibility).drip)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, " |  Unlocks in " + _toDisplayString(category.node?.dripDays || 0) + " " + _toDisplayString(category.node?.dripDays > 1 ? "days" : "day"), 1))
                      : _createCommentVNode("", true)
                  ]),
                  (category.node.visibility === _unref(PostVisibility).drip)
                    ? (_openBlock(), _createBlock(_unref(ClockStopwatchIcon), {
                        key: 0,
                        class: "h-5 min-w-5 mx-2 text-orange-300"
                      }))
                    : (isLocked(category, {}, {}))
                      ? (_openBlock(), _createBlock(_unref(Lock03Icon), {
                          key: 1,
                          class: "h-5 min-w-5 mx-2 text-red-300"
                        }))
                      : _createCommentVNode("", true),
                  _createVNode(_unref(ChevronUpIcon), {
                    class: _normalizeClass([{
              'rotate-180': !categoryExpandedMap.value[category.id],
              'rotate-0': categoryExpandedMap.value[category.id],
            }, "min-w-5 h-5 transform transition-transform duration-300 text-gray-400"])
                  }, null, 8, ["class"])
                ], 10, _hoisted_7),
                _createVNode(_Transition, { name: "slide-fade" }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.childs, (sub) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: sub.id,
                          class: "p-1 mb-2"
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["node relative ml-4", getSubClass(sub, category)]),
                            onClick: ($event: any) => (
                  postDetails(
                    category.node.productId,
                    category.id,
                    sub.id,
                    category,
                    sub,
                    {},
                    sub.type
                  )
                  )
                          }, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["w-2 h-2 rounded-full", getBackgroundClassForLessonPointer(
                        category,
                        sub,
                        {},
                        _ctx.allCompletedPostsData
                      )
                        ])
                                }, null, 2)
                              ]),
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("div", _hoisted_16, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass(["text-sm", getLessonCategoryDisabledClass(category, sub, {})])
                                  }, _toDisplayString(sub.node.title), 3),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createElementVNode("span", _hoisted_18, [
                                      _createElementVNode("span", _hoisted_19, _toDisplayString((sub.node?.contentType || sub?.type) === _unref(PostContentType).video ? _unref(PostContentType).lesson :
                                (sub.node?.contentType || sub?.type)), 1),
                                      (sub?.node?.visibility === _unref(PostVisibility).drip)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_20, " |  Unlocks in " + _toDisplayString(sub?.node?.dripDays
                                || 0) + " " + _toDisplayString(sub?.node?.dripDays > 1 ? "days" : "day"), 1))
                                        : (isLocked(category, sub, {}))
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, " |  Locked"))
                                          : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]),
                                (sub?.node?.visibility === _unref(PostVisibility).drip)
                                  ? (_openBlock(), _createBlock(_unref(ClockStopwatchIcon), {
                                      key: 0,
                                      class: "h-5 min-w-5 mx-2 text-orange-300"
                                    }))
                                  : (isLocked(category, sub, {}))
                                    ? (_openBlock(), _createBlock(_unref(Lock03Icon), {
                                        key: 1,
                                        class: "h-5 min-w-5 text-red-300 mr-3"
                                      }))
                                    : _createCommentVNode("", true)
                              ])
                            ])
                          ], 10, _hoisted_12),
                          _withDirectives(_createElementVNode("div", {
                            style: _normalizeStyle({ height: `${sub.childs.length * 70}px` })
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sub.childs, (lesson) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: lesson.id,
                                class: _normalizeClass(["sub-node relative ml-12 translate-y-9", getLessonClass(lesson, category)]),
                                onClick: ($event: any) => (
                      postDetails(
                        category.node?.productId,
                        sub.id,
                        lesson.id,
                        category,
                        sub,
                        lesson,
                        lesson.type
                      )
                      )
                              }, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("div", _hoisted_24, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["w-2 h-2 rounded-full", getBackgroundClassForLessonPointer(
                          category,
                          sub,
                          lesson,
                          _ctx.allCompletedPostsData
                        )
                          ])
                                    }, null, 2)
                                  ]),
                                  _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("div", _hoisted_26, [
                                      _createElementVNode("span", {
                                        class: _normalizeClass(["text-sm", getLessonCategoryDisabledClass(category, sub, lesson)])
                                      }, _toDisplayString(lesson.node.title), 3),
                                      _createElementVNode("div", _hoisted_27, [
                                        _createElementVNode("span", _hoisted_28, [
                                          _createElementVNode("span", _hoisted_29, _toDisplayString((lesson.node?.contentType || lesson?.type) === _unref(PostContentType).video ?
                                  _unref(PostContentType).lesson :
                                  (lesson.node?.contentType || lesson?.type)), 1),
                                          (isLocked(category, sub, lesson))
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_30, " |  Locked"))
                                            : _createCommentVNode("", true)
                                        ])
                                      ])
                                    ]),
                                    (isLocked(category, sub, lesson))
                                      ? (_openBlock(), _createBlock(_unref(Lock03Icon), {
                                          key: 0,
                                          class: "h-5 min-w-5 text-red-300 mr-3"
                                        }))
                                      : _createCommentVNode("", true)
                                  ])
                                ])
                              ], 10, _hoisted_22))
                            }), 128))
                          ], 4), [
                            [_vShow, sub.childs.length]
                          ])
                        ]))
                      }), 128))
                    ], 512), [
                      [_vShow, categoryExpandedMap.value[category.id]]
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]))
            }), 128))
          ], 512)
        ]))
  ]))
}
}

})