import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "block absolute top-0 left-0 z-1 w-full h-full",
  style: { backdropFilter: 'blur(4px)' }
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  id: "offer-price-display-override"
}
const _hoisted_4 = {
  key: 1,
  id: "offer-amount"
}
const _hoisted_5 = {
  class: "locked-icon absolute w-5 h-5 lg:w-8 lg:h-8 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2",
  style: { color: '#FFBC00' }
}
const _hoisted_6 = {
  key: 0,
  class: "absolute top-0 right-0 m-2 lg:py-1 lg:px-3 px-1 rounded text-gray-900 text-xs lg:text-sm",
  style: {"background-color":"#ffbc00"}
}
const _hoisted_7 = { class: "p-2 lg:px-2 lg:py-1" }
const _hoisted_8 = {
  key: 0,
  class: "px-2 pb-2 pt-0"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "w-full border-t pt-1 absolute bottom-0"
}
const _hoisted_11 = { class: "flex items-center justify-between py-1 px-2 w-full" }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { id: "instructor-headshot" }
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  id: "instructor-name",
  class: "text-sm font-semibold text-blue-600 pl-2"
}
const _hoisted_16 = { class: "offer-amount bg-nova-blue-900 py-1 px-2 rounded text-white text-sm" }
const _hoisted_17 = {
  key: 0,
  id: "offer-price-display-override"
}
const _hoisted_18 = {
  key: 1,
  id: "offer-amount"
}
const _hoisted_19 = {
  key: 0,
  class: "absolute h-full w-full shadow bg-white stack-1 rounded border-r border-b"
}
const _hoisted_20 = {
  key: 1,
  class: "absolute h-full w-full shadow bg-white stack-2 rounded border-r border-b"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UpsellModal = _resolveComponent("UpsellModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative upsell-card lg:m-4 transform transition duration-300 lg:hover:scale-105", _ctx.newMobileScreens ? 'h-full' : 'max-w-xs max-h-96'])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openUpsell && _ctx.openUpsell(...args))),
        class: _normalizeClass(["w-full h-full content z-10 relative nova-product-card bg-white overflow-hidden cursor-pointer", [
          _ctx.newMobileScreens
            ? !_ctx.notInCarousel
              ? 'shadow-none'
              : 'shadow rounded'
            : 'shadow rounded border border-gray-300 lg:hover:shadow-md',
        ]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex justify-center items-center product-image bg-center bg-contain bg-no-repeat w-full relative", _ctx.newMobileScreens && !_ctx.notInCarousel
              ? 'shadow rounded-lg'
              : _ctx.notInCarousel
                ? ''
                : 'border-b'
            ])
        }, [
          (!_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            class: "h-full rounded-lg lg:rounded-none w-full",
            src: _ctx.posterImage
          }, null, 8, _hoisted_2),
          (_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["absolute text-xs rounded text-white px-1 right-0 bottom-0 mr-2 mb-2 z-1", _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : 'bg-nova-blue-600'
              ])
              }, [
                (_ctx.offer.priceDisplayOverride)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.offer.priceDisplayOverride), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.fetchCurrencySymbol(_ctx.offer.currency)) + _toDisplayString(Number(_ctx.offer.amount).toFixed(2)), 1))
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseIcon, {
              name: "lockfilled",
              hwClass: "w-4 h-4"
            })
          ])
        ], 2),
        (_ctx.offer?.products && _ctx.offer.products?.length !== 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Bundle "))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["product-details lg:py-2 lg:my-1", _ctx.newMobileScreens ? '' : 'h-40'])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: _normalizeClass(["font-semibold product-title", _ctx.newMobileScreens
                ? !_ctx.notInCarousel && 'text-center'
                : 'text-left text-lg'
              ])
            }, _toDisplayString(_ctx.truncateText(_ctx.courseData.title)), 3)
          ]),
          (_ctx.courseData.description && !_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", {
                  class: "text-gray-800 text-sm product-title custom-list-styling",
                  innerHTML: _ctx.courseData.description
                }, null, 8, _hoisted_9)
              ]))
            : _createCommentVNode("", true)
        ], 2),
        (!_ctx.newMobileScreens)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("img", {
                      class: "h-12 w-12 rounded-full",
                      src: _ctx.replaceBucketUrlWithCdnUrl(
                  _ctx.courseData.instructorHeadshot || _ctx.userAvatarImage
                )
                  ,
                      alt: "Instructor"
                    }, null, 8, _hoisted_14)
                  ]),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.truncateText(
                    _ctx.courseData.instructorName || 'Instructor Name',
                    _ctx.instructorNameLimit
                  )), 1)
                ]),
                _createElementVNode("span", _hoisted_16, [
                  (_ctx.offer?.priceDisplayOverride)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.offer?.priceDisplayOverride), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.fetchCurrencySymbol(_ctx.offer.currency)) + _toDisplayString(Number(_ctx.offer.amount).toFixed(2)), 1))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (
        _ctx.offer?.products && _ctx.offer.products?.length > 1 && !_ctx.newMobileScreens
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_19))
        : _createCommentVNode("", true),
      (
        _ctx.offer?.products && _ctx.offer.products?.length > 1 && !_ctx.newMobileScreens
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_20))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_UpsellModal, {
          key: 0,
          showUpsellModal: _ctx.showModal,
          offer: _ctx.offer,
          upsellId: _ctx.upsellId,
          purchasedProductIds: _ctx.purchasedProductIds,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false)),
          onCheckout: _ctx.handleUpsellCheckout
        }, null, 8, ["showUpsellModal", "offer", "upsellId", "purchasedProductIds", "onCheckout"]))
      : _createCommentVNode("", true)
  ]))
}