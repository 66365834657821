import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "audio-content" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "audio-details" }
const _hoisted_4 = { class: "audio-title" }
const _hoisted_5 = { class: "audio-product" }
const _hoisted_6 = ["src"]

import { ref, onMounted, onUnmounted, computed } from 'vue'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { AUDIO_FILE_PLACEHOLDER } from '@/helper/constants'
import { useStore } from 'vuex'
import { AudioService } from '@/services'
import { isMobileTablet } from '@/helper/device_info'

export default /*@__PURE__*/_defineComponent({
  __name: 'AudioPlayer',
  props: {
  audio: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: String,
    default: AUDIO_FILE_PLACEHOLDER,
  },
  post: {
    type: Object,
    default: () => ({}),
  },
  product: {
    type: Object,
    default: () => ({}),
  },
  maxWidth: {
    type: Boolean,
    default: true,
  },
},
  emits: [
  'onPause',
  'onEnded',
  'onPlay',
  'onError',
  'getAudioTime',
],
  setup(__props, { expose: __expose, emit: __emit }) {

const store = useStore()
const props = __props

const emit = __emit

const plyrAudio = ref<any>(null)
const player = ref<Plyr>(null)
const watchTime = ref(0)
const lastUpdateTime = ref(0)
const isPlaying = ref(false)

onMounted(() => {
  initPlayer()
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  if (player.value) player.value.destroy()
  window.removeEventListener('resize', handleResize)
})

//add a computed field for

function truncateString(str: string, num: number) {
  if (store.state.newMobileScreens) {
    num = 30
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function initPlayer() {
  const audioOptions = {
    autoplay: false,
    controls: [
      'rewind',
      'play',
      'fast-forward',
      'progress',
      'current-time',
      'duration',
      'mute',
      'settings',
      'volume',
    ],
    speed: {
      selected: 1,
      options: [0.5, 1, 1.5, 1.75, 2],
    },
  }

  try {
    player.value = new Plyr(plyrAudio.value, {
      ...audioOptions,
    })
    initTriggers(player.value)
  } catch (error) {
    console.error('Error while initializing player: ', error)
  }
}

function handleResize() {
  // Reapply the necessary styles after resizing
  const progressBar = document.querySelector('.plyr__progress--played')
  const bufferBar = document.querySelector('.plyr__progress--buffer')
  const volumeButton = document.querySelector('.plyr__control--overlaid')

  if (progressBar) {
    progressBar.style.background = 'white'
  }

  if (bufferBar) {
    bufferBar.style.background = 'rgba(255, 255, 255, 0.5)'
  }

  if (volumeButton) {
    volumeButton.style.color = '#00b2ff'
  }
}

function initTriggers(playerInstance: Plyr) {
  playerInstance.on('error', (e: any) => {
    emit('onError', e)
    sendPlaybackErrorLogs(e)
    console.error('Error while initializing player...')
  })
  playerInstance.on('play', (e: any) => {
    emit('onPlay', e)
    isPlaying.value = true
    lastUpdateTime.value = playerInstance.currentTime
  })
  playerInstance.on('pause', (e: any) => {
    emit('onPause', e)
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for pause: ', error)
    }
  })
  playerInstance.on('ended', (e: any) => {
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for ended: ', error)
    }
    emit('onEnded', e)
  })
  playerInstance.on('timeupdate', () => {
    emit('getAudioTime', [playerInstance.currentTime, playerInstance.duration])
  })
}

async function sendWatchTimeLogs() {
  if (watchTime.value > 0) {
    const logPayload = {
      locationId: props.audio.locationId,
      watchTime: watchTime.value,
      postId: props.post.id,
      videoUrl: replaceBucketUrlWithCdnUrl(props.audio.url),
    }

    await AudioService.sendWatchtimeLogs(logPayload)
  }
}

async function sendPlaybackErrorLogs(error: any) {
  const logPayload = {
    locationId: props.audio.locationId,
    postId: props.post.id,
    audioUrl: replaceBucketUrlWithCdnUrl(props.audio.url),
    deviceName: navigator.userAgent,
    deviceType: isMobileTablet() ? 'mobile' : 'desktop',
    playbackError: error,
  }

  await AudioService.sendPlaytimeErrorLogs(logPayload)
}

__expose({
  player,
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'audio-player-container',
      { 'max-width-48rem': __props.maxWidth },
      { 'max-width-67rem': !__props.maxWidth },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: __props.thumbnail
        ? _unref(replaceBucketUrlWithCdnUrl)(__props.thumbnail)
        : _unref(AUDIO_FILE_PLACEHOLDER)
        ,
        alt: "Album Art",
        class: "album-art"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "audio-label" }, "Audio", -1)),
        _createElementVNode("div", _hoisted_4, _toDisplayString(truncateString(__props.post.title, __props.maxWidth ? 25 : 40)), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(truncateString(__props.product.title, 50)), 1)
      ])
    ]),
    (__props.audio)
      ? (_openBlock(), _createElementBlock("audio", {
          key: 0,
          controls: "",
          src: _unref(replaceBucketUrlWithCdnUrl)(__props.audio.url),
          id: "plyr-audio",
          ref_key: "plyrAudio",
          ref: plyrAudio,
          preload: "auto",
          crossorigin: "anonymous",
          class: "audio-element"
        }, null, 8, _hoisted_6))
      : _createCommentVNode("", true)
  ], 2))
}
}

})