import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "product-title",
  class: "text-white font-bold text-4xl"
}
const _hoisted_2 = {
  key: 0,
  id: "product-description",
  class: "text-white text-xl py-2"
}
const _hoisted_3 = {
  id: "product-status",
  class: "mt-8"
}
const _hoisted_4 = {
  key: 0,
  id: "completion-message",
  class: "font-semibold text-2xl text-white"
}
const _hoisted_5 = {
  key: 1,
  id: "partial-completion-message",
  class: "font-semibold text-xl text-white"
}
const _hoisted_6 = {
  key: 3,
  class: "text-center text-white flex flex-col items-center justify-center"
}
const _hoisted_7 = {
  id: "product-details-container",
  class: "max-w-6xl mx-auto grid md:grid-cols-5 gap-4 my-10"
}
const _hoisted_8 = { class: "category-post-list-container md:col-span-3" }
const _hoisted_9 = { class: "lesson-stats-instructor-container md:col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock01Icon = _resolveComponent("Lock01Icon")!
  const _component_UITextLgMedium = _resolveComponent("UITextLgMedium")!
  const _component_CategoryPostTree = _resolveComponent("CategoryPostTree")!
  const _component_LessonsStats = _resolveComponent("LessonsStats")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["h-auto bg-cover bg-no-repeat bg-center bg-gray-300 w-full flex justify-center items-center", _ctx.heroSection.alignment]),
      id: "banner",
      style: _normalizeStyle({ backgroundImage: `url(${_ctx.replaceBucketUrlWithCdnUrl(_ctx.heroSection.background)})`})
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle(`background-color: ${_ctx.heroSection.overlayColor}`),
        class: "overlay"
      }, null, 4),
      _createElementVNode("div", {
        id: "hero-section",
        class: _normalizeClass(["xl:px-24 lg:x-16 md:px-12 px-6 w-full", _ctx.heroSection.spacing])
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.product.title || 'Fetching Product..'), 1),
        (_ctx.product.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.product.description), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.courseCompleted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Congrats!! You have completed this course! "))
            : _createCommentVNode("", true),
          (!_ctx.courseCompleted && !(_ctx.nextPost && _ctx.nextPost.id) && _ctx.hasProductAccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " You seem to have completed all available contents!! "))
            : _createCommentVNode("", true),
          (_ctx.nextPost && _ctx.nextPost.id && _ctx.hasProductAccess)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                id: "load-next-post",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNextPostClick())),
                class: "inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none transition duration-150 ease-in-out"
              }, _toDisplayString(_ctx.buttonText) + " Course ", 1))
            : _createCommentVNode("", true),
          (!_ctx.hasProductAccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Lock01Icon, { class: "h-20 w-20" }),
                _createVNode(_component_UITextLgMedium, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.productAccessMessage), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 6),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_CategoryPostTree, {
          level: 0,
          "category-tree": _ctx.categoryTree,
          "completed-posts": _ctx.postIdsCompleted,
          "completed-categories": _ctx.categoryIdsCompleted,
          hasProductAccess: _ctx.hasProductAccess
        }, null, 8, ["category-tree", "completed-posts", "completed-categories", "hasProductAccess"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_LessonsStats, {
          completed: _ctx.completedPosts,
          total: _ctx.totalPosts,
          class: "mb-3"
        }, null, 8, ["completed", "total"]),
        _createVNode(_component_InstructorCard, { instructor: _ctx.instructor }, null, 8, ["instructor"])
      ])
    ])
  ]))
}