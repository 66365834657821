import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "post-container w-dvw" }
const _hoisted_4 = { class: "w-11/12 mx-auto my-4 flex flex-col items-center justify-start flex-grow gap-2" }
const _hoisted_5 = { class: "w-full py-4 mx-auto flex items-center justify-between" }
const _hoisted_6 = { class: "flex flex-col items-start" }
const _hoisted_7 = { class: "text-gray-400 text-xs" }
const _hoisted_8 = { class: "text-gray-900 text-base font-semibold line-clamp-2" }
const _hoisted_9 = { class: "flex items-center gap-2" }
const _hoisted_10 = {
  key: 0,
  class: "w-full my-4 mx-auto inline"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "w-full my-4 mx-auto flex items-center gap-1 overflow-x-scroll min-h-32",
  style: { 'overflow-wrap': 'anywhere' }
}
const _hoisted_13 = ["href"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "text-gray-900 text-xs mt-2 text-center line-clamp-2" }
const _hoisted_16 = {
  key: 0,
  class: "w-11/12 my-2 mx-auto"
}
const _hoisted_17 = { class: "w-11/12 mx-auto fixed bottom-0 py-4 bg-white" }
const _hoisted_18 = { key: 1 }

import UISpinner from "@/components/common/UISpinner.vue";
import VideoPlayer from "@/components/product/VideoPlayer.vue";
import { findNextPostId, findPreviousPostId, renderFileTypeIcon, sanitizeJavaScriptCode } from "@/helper";
import { imageIcon, newDocLogo, newPdfLogo } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import { CategoryTreeNodeType } from "@/models/CategoryTreeNode";
import { PostContentType } from "@/models/Post";
import { PostMaterialType } from "@/models/PostMaterial";
import { ChevronLeftIcon, ChevronRightIcon } from "@gohighlevel/ghl-icons/24/outline";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { generateToken } from "../../../http-common";
import AudioPlayer from "../product/AudioPlayer.vue";

const MAX_DESCRIPTION_CHARACTERS = 200;


export default /*@__PURE__*/_defineComponent({
  __name: 'MobilePostLessonTheme',
  props: [
    "quiz",
    "product",
    "instructor",
    "activePost",
    "category",
    "videoOptions",
    "videoCompletePercentage",
    "playlist",
    "lessonButton",
    "isAdmin",
    "categoryTree",
    "nextLesson",
    "disableActions",
    "fetchingPost",
    "nextCategoryId",
    "completed",
    "playlist",
    "post",
    "postCompletionButtonText",
    "postMarkedAsCompleted",
    "disablePostCompletionButton",
    "totalPosts",
    "fetchingPostCompletionStatus",
    "lessonSequenceMap"
],
  emits: [
    "togglePostCompletion",
    "updateBackData",
    "storeVideoProgress",
    "updateVideoTime",
    "updateVideoPlay",
    "updateVideoTime",
    "updateAudioPlay",
    "storeAudioProgress",
    "updateAudioTime"
],
  setup(__props, { emit: __emit }) {

const store = useStore();
const route = useRoute();
const router = useRouter();
const device = computed(() => store.getters.device);




const emit = __emit;

const togglePostCompletion = () => {
    if (__props.disablePostCompletionButton) return;
    emit("togglePostCompletion");
};

const token = ref<string>();
const isExpanded = ref(false);
const nextPost = ref<any>(null);
const previousPost = ref<any>(null);

const mobilePlayerRef = ref<HTMLElement | null>(null);
const mobilePlayerHeight = ref<number | null>(null);
const windowHeight = ref(window.innerHeight);

const availableCategoryTree = computed(() => {
    return __props.categoryTree.filter((node) => node.node.available);
});

const availablePlayList = computed(() => {
    return __props.playlist.filter(
        (node) =>
            node.type === CategoryTreeNodeType.Post ||
            (node.type === CategoryTreeNodeType.Category && node.node.available)
    );
});

const isCurrentPostAssignment = computed(() => {
    return __props.activePost.contentType === PostContentType.assignment;
});

const needsViewMore = computed(() => {
    const maxLength = (__props.activePost?.video || __props.activePost?.audio) ? MAX_DESCRIPTION_CHARACTERS : 2000;

    return (
        __props.activePost?.description && sanitizeJavaScriptCode(__props.activePost.description).length > maxLength
    );
});

/**
 * This computed property determines the class applied to the description based on whether the description is expanded or not.
 * It uses the `isExpanded` and `needsViewMore` computed properties to decide the class.
 * 
 * - If the description is not expanded and needs to be viewed more, and the post has a video or audio, it applies `!max-h-20 line-clamp-3 overflow-y-hidden`.
 * - If the description is not expanded and needs to be viewed more, and the post does not have a video or audio, it applies `line-clamp-20`.
 * 
 * This ensures that the description is displayed with the appropriate number of lines based on the post's media presence.
 */
const getDescriptionClass = computed(() => {
    return {
        "!max-h-20 line-clamp-3 overflow-y-hidden": !isExpanded.value && needsViewMore.value && (__props.activePost?.video || __props.activePost?.audio),
        "line-clamp-20": !isExpanded.value && needsViewMore.value && !(__props.activePost?.video || __props.activePost?.audio),
    };
});

const toggleView = () => {
    isExpanded.value = !isExpanded.value;
};

function updateVideoPlay() {
    emit('updateVideoPlay')
}
function storeVideoProgress() {
    emit('storeVideoProgress')
}
function getVideoTime(currentTime: Array<number>) {
    emit('updateVideoTime', currentTime)
}



function getPreviousPostData(postId) {
    previousPost.value = findPreviousPostId(
        availableCategoryTree.value,
        postId,
        __props.category,
        availablePlayList.value
    );
}
function getNextPostData(postId) {
    nextPost.value = findNextPostId(
        availableCategoryTree.value,
        postId,
        __props.category,
        availablePlayList.value
    );
}



function handleNextPostClick() {
    if (nextPost.value?.postId) {
        if (nextPost.value?.contentType === PostContentType.assignment) {
            router.push({
                name: "mobile-assignment",
                params: {
                    id: __props.product.id,
                    post_id: nextPost.value.postId,
                    category_id: nextPost.value.categoryId,
                },
                query: {},
            });
        } else if (nextPost.value?.contentType === PostContentType.quiz) {
            router.push({
                name: "mobile-quiz-overview",
                params: {
                    id: __props.product.id,
                    post_id: nextPost.value.postId,
                    category_id: nextPost.value.categoryId,
                },
            });
        } else {
            router.push({
                name: "post-overview",
                params: {
                    id: __props.product.id,  // Consistent parameter naming
                    category_id: nextPost.value.categoryId || __props.category.id,
                    post_id: nextPost.value.postId,
                },
            });
        }
    }
}


function navigateToPost(post, product, category) {
    // Determine the route name based on the content type
    let routeName;

    if (post.value?.contentType === PostContentType.assignment) {
        routeName = "mobile-assignment";
    } else if (post.value?.contentType === PostContentType.quiz) {
        routeName = "mobile-quiz-overview";
    } else {
        routeName = "post-overview";
    }

    // Construct the route parameters
    const routeParams = {
        id: product.id,
        post_id: post.value.postId,
        category_id: post.value.categoryId || category.id,
    };

    // Navigate to the determined route
    router.push({
        name: routeName,
        params: routeParams,
        query: routeName === "mobile-assignment" ? {} : undefined,  // Include query only for "mobile-assignment"
    });
}

function handlePreviousPostClick() {
    if (previousPost.value?.postId) {
        navigateToPost(previousPost, __props.product, __props.category)
    }
}

function startAssessment(query) {
    if (__props.disablePostCompletionButton) return;
    if (__props.activePost.contentType === PostContentType.assignment) {
        router.push({
            name: "mobile-assignment-overview",
            params: {
                id: __props.product.id,
                post_id: __props.activePost.id,
                category_id: __props.category.id,
            },
            query,
        });
    } else {
        router.push({
            name: "mobile-quiz-questions-levelup",
            params: {
                id: __props.product.id,
                post_id: __props.activePost.id,
                category_id: __props.category.id,
                quiz_id: __props.quiz.id,
            },
        });
    }
}

const calculatePostContainerHeight = () => {
    //?? Mobile player height - header height from total real estate will be container height
    return `calc(100dvh - 100px - ${mobilePlayerHeight.value}px)`;
};


// Function to get the height of the element
const getMobilePlayerHeight = () => {
    if (mobilePlayerRef.value) {
        mobilePlayerHeight.value = mobilePlayerRef.value.offsetHeight;
    }
};

const updateWindowHeight = () => {
    windowHeight.value = window.innerHeight;
    getMobilePlayerHeight();
};

const routeToRespectiveLesson = () => {
    if (__props.activePost.contentType === PostContentType.assignment) {
        router.push({
            name: "mobile-assignment",
            params: {
                id: __props.product.id,
                post_id: __props.activePost.id,
                category_id: __props.category.id,
            },
        });
    } else if (__props.activePost.contentType === PostContentType.quiz) {
        router.push({
            name: "mobile-quiz-overview",
            params: {
                id: __props.product.id,
                post_id: __props.activePost.id,
                category_id: __props.category.id,
                quiz_id: __props.quiz.id,
            },
        });
    }
}

function updateAudioPlay() {
    emit('updateAudioPlay')
}
function storeAudioProgress() {
    emit('storeAudioProgress')
}
function getAudioTime(currentTime: Array<number>) {
    emit('updateAudioTime', currentTime)
}

onBeforeUnmount(() => {
    window.removeEventListener("resize", updateWindowHeight);
});

watch(windowHeight, () => {
    getMobilePlayerHeight();
});

watch(
    () => route.params,
    async (newParams) => {
        getNextPostData(newParams.post_id), getPreviousPostData(newParams.post_id);
        token.value = await generateToken();
    }
);


onMounted(async () => {
    getMobilePlayerHeight();
    getNextPostData(route.params.post_id);
    getPreviousPostData(route.params.post_id);
    token.value = await generateToken();
    getMobilePlayerHeight();
    window.addEventListener("resize", updateWindowHeight);
    if (route.query?.from_search === 'true') {
        routeToRespectiveLesson()
    }
});



return (_ctx: any,_cache: any) => {
  return (device.value === 'mobile')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (__props.activePost?.video || __props.activePost?.audio)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  id: "mobile-player",
                  class: "mt-4 mx-auto rounded-md",
                  style: { width: '95%' },
                  ref_key: "mobilePlayerRef",
                  ref: mobilePlayerRef
                }, [
                  (__props.activePost?.video && __props.videoOptions)
                    ? (_openBlock(), _createBlock(VideoPlayer, {
                        key: 0,
                        id: "post-video",
                        class: "h-full w-full rounded-md",
                        loading: __props.fetchingPost,
                        video: __props.activePost && __props.activePost?.video,
                        options: __props.videoOptions,
                        "video-thumbnail": __props.activePost?.posterImage,
                        trackTime: true,
                        videoCompletePercentage: __props.videoCompletePercentage,
                        onGetVideoTime: getVideoTime,
                        onOnPause: storeVideoProgress,
                        onOnEnded: storeVideoProgress,
                        onOnPlay: updateVideoPlay
                      }, null, 8, ["loading", "video", "options", "video-thumbnail", "videoCompletePercentage"]))
                    : (__props.activePost.audio)
                      ? (_openBlock(), _createBlock(AudioPlayer, {
                          key: 1,
                          id: "post-audio",
                          audio: __props.activePost.audio,
                          thumbnail: __props.activePost.posterImage,
                          post: __props.activePost,
                          product: __props.product,
                          onGetAudioTime: getAudioTime,
                          onOnPause: storeAudioProgress,
                          onOnEnded: storeAudioProgress,
                          onOnPlay: updateAudioPlay,
                          class: "rounded-md"
                        }, null, 8, ["audio", "thumbnail", "post", "product"]))
                      : _createCommentVNode("", true)
                ], 512))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "flex flex-col items-center justify-between overflow-y-scroll relative pb-4",
              style: _normalizeStyle({ height: calculatePostContainerHeight() })
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, "Lesson " + _toDisplayString(__props.lessonSequenceMap.get(__props.activePost?.id) ?? 0) + " of " + _toDisplayString(__props.totalPosts), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(__props.activePost?.title), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["min-w-8 max-w-8 h-8 rounded-md border p-1 transition-all duration-200 ease-in-out clickable", [
                                        previousPost.value ? 'bg-gray-100' : 'bg-gray-300 cursor-not-allowed',
                                        previousPost.value ? 'hover:bg-gray-300 active:scale-95' : '',
                                    ]]),
                      onClick: handlePreviousPostClick
                    }, [
                      _createVNode(_unref(ChevronLeftIcon), { class: "text-[#667085] w-full h-full" })
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["min-w-8 max-w-8 h-8 rounded-md border p-1 transition-all duration-150 ease-in-out clickable", [
                                        nextPost.value ? 'bg-gray-100' : 'bg-gray-300 cursor-not-allowed',
                                        nextPost.value ? 'hover:bg-gray-300 active:scale-95' : '',
                                    ]]),
                      onClick: handleNextPostClick
                    }, [
                      _createVNode(_unref(ChevronRightIcon), { class: "text-[#667085] w-full h-full" })
                    ], 2)
                  ])
                ]),
                (__props.activePost?.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["post-description mobile-post-details break-all w-full overflow-x-scroll text-gray-400 font-normal", getDescriptionClass.value]),
                        innerHTML: __props.activePost?.description
                      }, null, 10, _hoisted_11),
                      (needsViewMore.value && !isExpanded.value)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: toggleView,
                            class: "text-blue-500 font-semibold text-sm cursor-pointer clickable"
                          }, " View More "))
                        : _createCommentVNode("", true),
                      (needsViewMore.value && isExpanded.value)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            onClick: toggleView,
                            class: "text-blue-500 font-semibold text-sm cursor-pointer clickable"
                          }, " View Less "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (__props.activePost?.materials?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.activePost?.materials, (material) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: material.id,
                          class: "min-w-24 w-24 h-24 cursor-pointer flex-shrink-0"
                        }, [
                          _createElementVNode("a", {
                            class: "text-blue-500 flex flex-col items-center post-material-link",
                            target: "_blank",
                            href: _unref(replaceBucketUrlWithCdnUrl)(material?.url)
                          }, [
                            _createElementVNode("img", {
                              class: "w-12 h-16 rounded-md",
                              src: _unref(renderFileTypeIcon)(material.title)
                            }, null, 8, _hoisted_14),
                            _createElementVNode("span", _hoisted_15, _toDisplayString(material?.title), 1)
                          ], 8, _hoisted_13)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (__props.activePost?.contentType === _unref(PostContentType).quiz ||
                        __props.activePost?.contentType === _unref(PostContentType).assignment
                    )
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      "data-v-b7d9666a": "",
                      class: "p-10 w-full",
                      src: "https://storage.googleapis.com/revex-membership-production/assets/assignment_banner.svg",
                      alt: "quiz-start"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, [
                (
                            __props.activePost?.contentType === _unref(PostContentType).quiz ||
                            __props.activePost?.contentType === _unref(PostContentType).assignment
                        )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      id: "start-assessment",
                      class: "w-full rounded-lg flex items-center justify-center clickable py-0 px-4 text-sm font-semibold h-9 cursor-pointer",
                      style: {
                                backgroundColor: 'var(--primary-color) !important',
                                color: 'white',
                            },
                      onClick: startAssessment
                    }, [
                      _createElementVNode("span", null, " Start " + _toDisplayString(isCurrentPostAssignment.value ? "Assignment" : "Quiz"), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      id: "mark-as-complete",
                      class: "w-full rounded-lg flex items-center justify-center clickable py-0 px-4 text-sm font-semibold h-9 cursor-pointer border border-black",
                      style: _normalizeStyle({
                                backgroundColor: __props.postMarkedAsCompleted
                                    ? 'var(--primary-color)'
                                    : 'white',
                                color: __props.postMarkedAsCompleted ? 'white' : 'black',
                            }),
                      onClick: togglePostCompletion
                    }, [
                      (__props.fetchingPostCompletionStatus)
                        ? (_openBlock(), _createBlock(UISpinner, {
                            key: 0,
                            class: "min-w-6 h-6"
                          }))
                        : (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(__props.postCompletionButtonText), 1))
                    ], 4))
              ])
            ], 4)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})