import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = {
  key: 0,
  class: "border-r border-gray-500 pr-2 mr-2"
}
const _hoisted_4 = {
  key: 1,
  class: "border-r border-gray-500 pr-2 mr-2"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "post-details mt-4" }
const _hoisted_7 = { class: "video-post-body-container" }
const _hoisted_8 = {
  key: 0,
  class: "my-auto"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "py-2 px-4 lg:px-0 text-center md:text-left font-medium text-gray-600 lessonSecondaryStyle lesson-count"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex justify-center items-center font-semibold" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = {
  key: 0,
  class: "py-2 px-4 text-center md:text-center font-medium text-gray-600 lessonSecondaryStyle lesson-count"
}
const _hoisted_16 = { class: "capitalize" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = {
  key: 2,
  class: "post-status-fetch-message flex justify-end items-center"
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = ["disabled"]
const _hoisted_21 = { class: "px-2 inline-flex items-center" }
const _hoisted_22 = {
  key: 0,
  id: "post-description",
  class: "course-body-height lessonSecondaryStyle lesson-body-description custom-word-break"
}
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileNewYorkLessonTheme = _resolveComponent("MobileNewYorkLessonTheme")!
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_CategoryPostBreadcrumbs = _resolveComponent("CategoryPostBreadcrumbs")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_QuizStatusComponent = _resolveComponent("QuizStatusComponent")!
  const _component_AssignmentStatus = _resolveComponent("AssignmentStatus")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_new_york_post_queue = _resolveComponent("new-york-post-queue")!
  const _component_CommentList = _resolveComponent("CommentList")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!

  return (_ctx.newMobileScreens && !_ctx.isBuilder)
    ? (_openBlock(), _createBlock(_component_MobileNewYorkLessonTheme, {
        key: 0,
        product: _ctx.product,
        instructor: _ctx.instructor,
        activePost: _ctx.activePost,
        category: _ctx.category,
        videoOptions: _ctx.videoOptions,
        playlist: _ctx.playlist,
        disableActions: _ctx.disableActions,
        lessonButton: _ctx.lessonButton,
        isAdmin: _ctx.isAdmin,
        categoryTree: _ctx.categoryTree,
        fetchingPost: _ctx.fetchingPost,
        videoCompletePercentage: _ctx.videoCompletePercentage,
        postMarkedAsCompleted: _ctx.postMarkedAsCompleted,
        postCompletionButtonText: _ctx.postCompletionButtonText,
        disablePostCompletionButton: _ctx.disablePostCompletionButton,
        fetchingPostCompletionStatus: _ctx.fetchingPostCompletionStatus,
        completedPostIds: _ctx.completedPostIds,
        onTogglePostCompletion: _ctx.togglePostCompletion,
        derivePostDescription: _ctx.derivePostDescription,
        findSubstringInstances: _ctx.findSubstringInstances,
        expandPostDetails: _ctx.expandPostDetails,
        gotoPreviousPost: _ctx.gotoPreviousPost,
        goToNextPost: _ctx.goToNextPost,
        onUpdateBackData: _ctx.updateBackData,
        onStoreVideoProgress: _ctx.storeVideoProgress,
        onUpdateVideoPlay: _ctx.updateVideoPlay,
        onGetVideoTime: _ctx.getVideoTime
      }, null, 8, ["product", "instructor", "activePost", "category", "videoOptions", "playlist", "disableActions", "lessonButton", "isAdmin", "categoryTree", "fetchingPost", "videoCompletePercentage", "postMarkedAsCompleted", "postCompletionButtonText", "disablePostCompletionButton", "fetchingPostCompletionStatus", "completedPostIds", "onTogglePostCompletion", "derivePostDescription", "findSubstringInstances", "expandPostDetails", "gotoPreviousPost", "goToNextPost", "onUpdateBackData", "onStoreVideoProgress", "onUpdateVideoPlay", "onGetVideoTime"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        id: "post-details-container newyork-template",
        class: _normalizeClass([
      _ctx.disableActions && _ctx.disableActions.pointerEvents === 'none' ? 'px-2' : '',
      _ctx.fetching ? 'flex justify-center items-center h-scree' : '',
    ])
      }, [
        (_ctx.fetching)
          ? (_openBlock(), _createBlock(_component_UISpinner, {
              key: 0,
              size: "large"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_CategoryPostBreadcrumbs, {
                  class: _normalizeClass((_ctx.activePost.video || _ctx.activePost.audio) && 'lg:px-8'),
                  "product-id": _ctx.productId,
                  showIcon: true,
                  "product-title": _ctx.product && _ctx.product.title,
                  "go-back-icon-color": _ctx.goBackIcon
                }, null, 8, ["class", "product-id", "product-title", "go-back-icon-color"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["text-gray-500 flex font-medium pl-4 pt-2 md:pt-0 lessonSecondaryStyle lesson-path", (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:pr-10'])
                }, [
                  (_ctx.shortendparentCategoryTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.shortendparentCategoryTitle), 1))
                    : _createCommentVNode("", true),
                  (_ctx.categoryTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.categoryTitle), 1))
                    : _createCommentVNode("", true),
                  (_ctx.postTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.postTitle), 1))
                    : _createCommentVNode("", true)
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.activePost.video && _ctx.videoOptions)
                    ? (_openBlock(), _createBlock(_component_video_player, {
                        key: 0,
                        id: "post-video",
                        class: "items-center mb-5 player-height lg:px-10",
                        loading: _ctx.fetchingPost,
                        video: _ctx.activePost && _ctx.activePost.video,
                        assetUrl: _ctx.activePost && _ctx.activePost.assetUrls,
                        options: _ctx.videoOptions,
                        "video-width": "w-full",
                        trackTime: true,
                        videoCompletePercentage: _ctx.videoCompletePercentage,
                        "video-thumbnail": _ctx.activePost.posterImage,
                        onGetVideoTime: _ctx.getVideoTime,
                        onOnPause: _ctx.storeVideoProgress,
                        onOnEnded: _ctx.storeVideoProgress,
                        onOnPlay: _ctx.updateVideoPlay
                      }, null, 8, ["loading", "video", "assetUrl", "options", "videoCompletePercentage", "video-thumbnail", "onGetVideoTime", "onOnPause", "onOnEnded", "onOnPlay"]))
                    : (_ctx.activePost.audio)
                      ? (_openBlock(), _createBlock(_component_audio_player, {
                          key: 1,
                          class: "player-height mb-4",
                          audio: _ctx.activePost.audio,
                          thumbnail: _ctx.activePost.posterImage,
                          post: _ctx.activePost,
                          product: _ctx.product,
                          "max-width": false,
                          onGetAudioTime: _ctx.getAudioTime,
                          onOnPause: _ctx.storeAudioProgress,
                          onOnEnded: _ctx.storeAudioProgress,
                          onOnPlay: _ctx.updateAudioPlay
                        }, null, 8, ["audio", "thumbnail", "post", "product", "onGetAudioTime", "onOnPause", "onOnEnded", "onOnPlay"]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(["relative post-body bg-white p-5 shadow rounded lesson-body-background overflow-y-scroll", [
              _ctx.expandPostData ? 'expanded-post-body' : 'post-body-description',
              _ctx.currentPostContentType === _ctx.postContentType.quiz
                ? 'flex justify-center'
                : '',
            ]]),
                          onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showExpandIcon = true)),
                          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showExpandIcon = false))
                        }, [
                          (_ctx.currentPostContentType === _ctx.postContentType.quiz)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                _createVNode(_component_QuizStatusComponent, {
                                  post: _ctx.activePost,
                                  categoryId: _ctx.categoryId,
                                  onChangeQuizStatus: _ctx.changeQuizStatus
                                }, null, 8, ["post", "categoryId", "onChangeQuizStatus"])
                              ]))
                            : (_ctx.currentPostContentType === _ctx.postContentType.assignment)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_AssignmentStatus, {
                                    post: _ctx.activePost,
                                    categoryId: _ctx.categoryId,
                                    showRetakeButton: true,
                                    nextPost: _ctx.nextPostData,
                                    onGoToNextPost: _ctx.goToNextPost
                                  }, null, 8, ["post", "categoryId", "nextPost", "onGoToNextPost"])
                                ]))
                              : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            id: "post-description",
                            class: "lessonSecondaryStyle lesson-body-description mb-5 custom-word-break custom-list-styling",
                            innerHTML: _ctx.activePost.description
                          }, null, 8, _hoisted_10)
                        ], 34))
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["mt-5 grid", [
            (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:px-10',
            _ctx.currentPostContentType === _ctx.postContentType.video ||
            _ctx.currentPostContentType === _ctx.postContentType.audio
              ? 'grid-cols-3'
              : 'buttons-parent-container',
          ]])
                }, [
                  (
              _ctx.currentPostContentType === _ctx.postContentType.video ||
              _ctx.currentPostContentType === _ctx.postContentType.audio
            )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Lesson " + _toDisplayString(_ctx.currentPostIndex) + " of " + _toDisplayString(_ctx.totalPost), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("button", {
                      id: "previous-lesson-button",
                      disabled: !_ctx.previousPostData,
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.gotoPreviousPost && _ctx.gotoPreviousPost(...args))),
                      class: _normalizeClass(["w-32 bg-transparent py-2 px-2 border rounded flex justify-center items-center", [
                !_ctx.previousPostData
                  ? 'text-gray-500 border-gray-500'
                  : 'lessonPrimaryStyle navigation-btn text-blue-600 border-blue-600',
                _ctx.currentPostContentType === _ctx.postContentType.video ||
                _ctx.currentPostContentType === _ctx.postContentType.audio
                  ? 'mx-2'
                  : 'ml-auto',
              ]]),
                      style: _normalizeStyle(
                _ctx.previousPostData && { borderColor: _ctx.navigationButtonColor }
              )
                    }, [
                      _createElementVNode("span", {
                        class: "inline-flex items-center",
                        style: _normalizeStyle({
                  color: !_ctx.previousPostData ? '#a0aec0' : _ctx.navigationButtonColor,
                })
                      }, [
                        _createVNode(_component_BaseIcon, {
                          name: "chevronleft",
                          hwClass: "w-3 h-3"
                        })
                      ], 4),
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "pl-2 inline-flex items-center text-center" }, "Previous", -1))
                    ], 14, _hoisted_14),
                    (
                _ctx.currentPostContentType !== _ctx.postContentType.video &&
                _ctx.currentPostContentType !== _ctx.postContentType.audio
              )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createTextVNode(" Lesson " + _toDisplayString(_ctx.currentPostIndex) + " of " + _toDisplayString(_ctx.totalPost) + " ", 1),
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.currentPostContentType !== _ctx.postContentType.video &&
                _ctx.currentPostContentType !== _ctx.postContentType.audio
                  ? `: ${_ctx.currentPostContentType}`
                  : ''), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      id: "next-lesson-button",
                      disabled: !_ctx.nextPostData,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToNextPost && _ctx.goToNextPost(...args))),
                      class: _normalizeClass(["w-32 bg-transparent py-2 px-2 border rounded flex justify-center items-center", 
                !_ctx.nextPostData
                  ? 'text-gray-500 border-gray-500'
                  : 'lessonPrimaryStyle navigation-btn text-blue-600 border-blue-600'
              ]),
                      style: _normalizeStyle(_ctx.nextPostData && { borderColor: _ctx.navigationButtonColor })
                    }, [
                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "pr-2 text-center" }, "Next", -1)),
                      _createElementVNode("span", {
                        class: "inline-flex items-center",
                        style: _normalizeStyle({
                  color: !_ctx.nextPostData ? '#a0aec0' : _ctx.navigationButtonColor,
                })
                      }, [
                        _createVNode(_component_BaseIcon, {
                          name: "chevronright",
                          hwClass: "w-3 h-3"
                        })
                      ], 4)
                    ], 14, _hoisted_17)
                  ]),
                  (_ctx.fetchingPostCompletionStatus)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Fetching post status.. "))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: "pt-2 md:pt-0 bg-transparent flex justify-end items-center",
                        disabled: _ctx.disablePostCompletionButton || _ctx.isAdmin
                      }, [
                        _createElementVNode("button", {
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.togglePostCompletion())),
                          disabled: _ctx.disablePostCompletionButton || _ctx.isAdmin,
                          class: _normalizeClass(["cursor-pointer py-2 px-2 border rounded flex justify-center items-center bg-gray-100 lessonPrimaryStyle border-none focus:ring-0", _ctx.markCompletedBtnCss])
                        }, [
                          _createElementVNode("span", {
                            class: "inline-flex items-center",
                            style: _normalizeStyle({ color: _ctx.postCompletedIcon })
                          }, [
                            _createVNode(_component_BaseIcon, {
                              name: "correct",
                              hwClass: "w-4 h-4"
                            })
                          ], 4),
                          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.postCompletionButtonText), 1)
                        ], 10, _hoisted_20)
                      ], 8, _hoisted_19))
                ], 2),
                (_ctx.activePost.video || _ctx.activePost.audio)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["post-body mt-5", [
            _ctx.expandPost &&
              'p-5 bg-white shadow rounded lesson-body-background overflow-y-scroll',
            (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:mx-10',
          ]])
                    }, [
                      (_ctx.expandPost)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createElementVNode("span", {
                              class: "custom-list-styling custom-word-break",
                              innerHTML: _ctx.activePost.description
                            }, null, 8, _hoisted_23),
                            _createElementVNode("span", {
                              class: "text-blue-500 cursor-pointer pb-2",
                              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.expandPostDescription && _ctx.expandPostDescription(...args)))
                            }, "Show Less")
                          ]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "custom-list-styling custom-word-break",
                            innerHTML: _ctx.postDescription
                          }, null, 8, _hoisted_24))
                    ], 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["mt-4", (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:px-10'])
              }, [
                _createVNode(_component_new_york_post_queue, {
                  categoryTree: _ctx.categoryTree,
                  previousCategory: _ctx.previousCategory,
                  "completed-posts": _ctx.completedPostIds,
                  activePost: _ctx.activePost,
                  nextCategory: _ctx.nextCategory,
                  category: _ctx.category,
                  categoryNavIcon: _ctx.categoryNavIcon,
                  highlightBackground: _ctx.highlightBackground,
                  "highlight-color": _ctx.highlightColor
                }, null, 8, ["categoryTree", "previousCategory", "completed-posts", "activePost", "nextCategory", "category", "categoryNavIcon", "highlightBackground", "highlight-color"])
              ], 2),
              (_ctx.activePost.commentPermission !== 'hidden')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["w-full", (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:px-10'])
                  }, [
                    _createVNode(_component_CommentList, {
                      productCommentPrivacy: _ctx.product.commentPrivacy,
                      postId: _ctx.postId,
                      productId: _ctx.productId,
                      instructor: _ctx.instructor,
                      commentPermission: _ctx.activePost.commentPermission,
                      pendoPayload: { post: _ctx.activePost, product: _ctx.product, category: _ctx.category },
                      commentIdToScroll: _ctx.commentIdToScroll
                    }, null, 8, ["productCommentPrivacy", "postId", "productId", "instructor", "commentPermission", "pendoPayload", "commentIdToScroll"])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["mt-4", (_ctx.activePost.video || _ctx.activePost.audio) && 'lg:px-10'])
              }, [
                _createVNode(_component_InstructorCard, {
                  instructor: _ctx.instructor,
                  newYorkTheme: true
                }, null, 8, ["instructor"])
              ], 2)
            ]))
      ], 2))
}