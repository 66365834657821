import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border rounded my-1" }
const _hoisted_2 = { key: 0 }

import { FaceSmileIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  Placeholder,
  RequiredExtensions,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import { UIButton, UIPopover } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import {
  computed,
  onMounted,
  ref
} from 'vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { onClickOutside } from '@vueuse/core'

export default /*@__PURE__*/_defineComponent({
  __name: 'Editor',
  props: {
  editorText: {
    type: String,
    default: '',
  },
  desktopScreen: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
},
  emits: ['update:editorText', 'getInstance'],
  setup(__props, { expose: __expose, emit: __emit }) {

const editor = ref<any>(null)
const showEmojiPicker = ref<boolean>(false)
const modalRef = ref(null)

const props = __props
const emit = __emit
const content = computed({
  get() {
    return props.editorText
  },
  set(value) {
    emit('update:editorText', value)
  },
})

onMounted(() => {
  editor.value = new Editor({
    content: props.editorText,
    autofocus: false, // setting this true will scroll page to comment section
    extensions: [RequiredExtensions, ...(props?.placeholder ? [Placeholder.configure({ placeholder: props.placeholder, color: '#D0D5DD' })] : [])],
    onUpdate({ editor }) {
      content.value = editor.getHTML()
    },
  })
  emit('getInstance', editor.value)
})

function onSelectEmoji(emoji: {
  i: string
  n: string[]
  r: string // with skin tone
  t: string // skin tone
  u: string // without tone
}) {
  editor.value.commands.insertContent(emoji.i)
  closeEmojiPicker()
}

function toggleEmojiPicker() {
  showEmojiPicker.value = !showEmojiPicker.value
}

function closeEmojiPicker() {
  showEmojiPicker.value = false
}

function resetContent() {
  editor.value.commands.clearContent(true)
}

__expose({
  resetContent,
  closeEmojiPicker,
})

onClickOutside(modalRef, (event) => {
  closeEmojiPicker()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UIRichTextEditor), {
      editor: editor.value,
      containerClass: "force-border-0 overflow-x-auto custom-editor-styling"
    }, null, 8, ["editor"]),
    (__props.desktopScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(UIPopover), {
            placement: "bottom",
            style: {"padding":"0"},
            show: showEmojiPicker.value
          }, {
            trigger: _withCtx(() => [
              _createVNode(_unref(UIButton), {
                id: "id-testing",
                disabled: false,
                loading: false,
                ghost: false,
                quaternary: false,
                circle: false,
                text: true,
                class: "p-2",
                onClick: toggleEmojiPicker
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(FaceSmileIcon), { class: "w-4 h-4" })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_unref(EmojiPicker), {
                native: true,
                disableSkinTones: true,
                onSelect: onSelectEmoji,
                ref_key: "modalRef",
                ref: modalRef,
                "static-texts": { placeholder: 'Search emoji' }
              }, null, 512)
            ]),
            _: 1
          }, 8, ["show"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})