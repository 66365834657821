import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-1 mb-2 lg:mb-0"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mb-3 lg:mb-0 lg:p-2 col-span-2" }
const _hoisted_4 = { class: "flex lg:block justify-between items-center" }
const _hoisted_5 = { class: "col-span-1" }
const _hoisted_6 = {
  key: 1,
  class: "flex mt-2 text-gray-700 text-xs justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Progress = _resolveComponent("Progress")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["category-list-item rounded lg:my-4 p-4 lg:p-2 items-center", 
      _ctx.newMobileScreens
        ? 'bg-category-bg shadow'
        : 'bg-white grid grid-cols-4 gap-4'
    ])
  }, [
    (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "category-image w-40 h-auto",
            src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.category.posterImage) || _ctx.defaultPosterImage,
            alt: "Category"
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.notAvailable)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(["category-title font-semibold drip-category-title", 
            _ctx.newMobileScreens
              ? 'text-base text-gray-500 my-0'
              : 'text-lg text-current'
          ])
            }, [
              _createTextVNode(_toDisplayString(_ctx.category.title && _ctx.category.title.length > _ctx.headingLimit
              ? _ctx.category.title.slice(0, _ctx.headingLimit) + '...'
              : _ctx.category.title) + " ", 1),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "lg:float-right inline-block lg:inline" }, "(Coming Soon)", -1))
            ], 2))
          : (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: _normalizeClass(["category-title font-semibold", 
            _ctx.newMobileScreens
              ? 'text-base text-gray-700 my-0'
              : 'text-lg text-current'
          ])
            }, _toDisplayString(_ctx.category.title && _ctx.category.title.length > _ctx.headingLimit
              ? _ctx.category.title.slice(0, _ctx.headingLimit) + '...'
              : _ctx.category.title), 3)),
        (_ctx.newMobileScreens)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass(_ctx.notAvailable ? 'text-gray-500' : 'text-gray-700')
            }, [
              _createVNode(_component_BaseIcon, {
                name: "chevronright",
                hwClass: "w-4 h-5"
              })
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", {
        class: _normalizeClass([
          _ctx.newMobileScreens
            ? 'text-gray-600 my-1'
            : 'category-description text-base',
          'whitespace-pre-line',
        ])
      }, _toDisplayString(_ctx.category.description), 3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Progress, {
        "percent-completed": _ctx.progress,
        "progress-color": _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : ''
      }, null, 8, ["percent-completed", "progress-color"])
    ]),
    (_ctx.newMobileScreens)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.categoryProgressCount ? _ctx.categoryProgressCount.completedPosts : 0) + "/" + _toDisplayString(_ctx.categoryProgressCount ? _ctx.categoryProgressCount.totalPosts : 0) + " Completed ", 1))
      : _createCommentVNode("", true)
  ], 2))
}