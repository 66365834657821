import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UniversalSearch = _resolveComponent("UniversalSearch")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.disableActions),
    class: _normalizeClass(["flex flex-col", [
    _ctx.disableActions.pointerEvents === 'none' ? 'm-2' : '',
    _ctx.newMobileScreens
      ? 'h-screen'
      : `min-h-screen bg-gray-100 ${_ctx.getThemeClasses(_ctx.routeClass)}`,
  ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.newMobileScreens ? 'h-full' : _ctx.getThemeClasses(_ctx.containerClass))
    }, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { id: "productCustomHeader" }, null, -1)),
      (_ctx.fetching === false)
        ? (_openBlock(), _createBlock(_component_Navigation, {
            key: 0,
            logo: _ctx.headerSection.logoImage,
            showLogo: _ctx.headerLogoPlacement,
            alignment: _ctx.headerAlignment,
            backCallback: _ctx.backCallback,
            sections: _ctx.sections?.header,
            lessonSections: _ctx.lessonSections?.header
          }, null, 8, ["logo", "showLogo", "alignment", "backCallback", "sections", "lessonSections"]))
        : _createCommentVNode("", true),
      (!_ctx.fetching)
        ? (_openBlock(), _createBlock(_component_router_view, {
            key: 1,
            class: _normalizeClass(_ctx.newMobileScreens ? 'product-container-route overflow-y-auto' : 'flex-1'),
            product: _ctx.product,
            instructor: _ctx.instructor,
            disableActions: _ctx.disableActions,
            logo: _ctx.headerSection.logoImage,
            "product-started": _ctx.productStarted,
            selectedTheme: _ctx.selectedTheme,
            allCompletedPostsData: _ctx.allCompletedPostsData,
            onUpdateBackData: _ctx.updateBackData,
            onFetchAllCompletedPosts: _ctx.fetchAllCompletedPosts,
            onUpdateCompletedPostData: _ctx.updateCompletedPostData
          }, null, 8, ["class", "product", "instructor", "disableActions", "logo", "product-started", "selectedTheme", "allCompletedPostsData", "onUpdateBackData", "onFetchAllCompletedPosts", "onUpdateCompletedPostData"]))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createElementVNode("div", { id: "productCustomFooter" }, null, -1)),
      (!_ctx.newMobileScreens)
        ? (_openBlock(), _createBlock(_component_UniversalSearch, {
            key: 2,
            "show-modal": _ctx.showSearchModal,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearchModal = false))
          }, null, 8, ["show-modal"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 6))
}