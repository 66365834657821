import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full h-full mx-auto mt-2 gap-2 relative" }
const _hoisted_2 = { class: "comment-block w-full my-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "w-full bg-[#F2F2F2] h-9 p-2 flex items-center justify-between" }
const _hoisted_5 = {
  key: 0,
  class: "w-full bg-[#F2F2F2] h-9 p-2 flex items-center justify-between"
}
const _hoisted_6 = { class: "text-[--accent-color] text-xs font-normal" }
const _hoisted_7 = { class: "w-full flex items-center justify-start m-auto border-b border-[#EAECF0] my-2 min-h-14 px-3" }

import { getMobileTheme } from "@/helper";
import {
  COMMENT_MAX_LENGTH,
  MOBILE_HEADING_MAX_LENGTH,
  mobileCommentEditorConfig,
  userAvatarImage,
} from "@/helper/constants";
import Category from "@/models/Category";
import { CommentVisibility } from "@/models/Comment";
import Post from "@/models/Post";
import Product from "@/models/Product";
import { CommentService } from "@/services";
import { debounce } from 'lodash';

import {
  ChevronUpIcon,
  Globe01Icon,
  Lock01Icon,
  Send01Icon,
  XIcon
} from "@gohighlevel/ghl-icons/24/outline";
import { renderIcon } from "@gohighlevel/ghl-ui";
import { computed, onMounted, ref, watch } from "vue";
import Editor from "../common/Editor.vue";
import UISpinner from "../common/UISpinner.vue";
import CommentShimmerV2 from "../shimmer/mobile/CommentShimmerV2.vue";
import CommentV2 from "./CommentV2.vue";
import DrawerMenuV2 from "./DrawerMenuV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PostCommentsV2',
  props: {
  product: Product,
  activePost: Post,
  category: Category,
  instructor: Object,
},
  emits: ["updateBackData"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const loadMoreFetching = ref(false)
const fetching = ref(false);
const posting = ref(false);
const userComment = ref("");
const commentMaxLength = ref(COMMENT_MAX_LENGTH);
const comments = ref([]);
const commentsLimit = ref(5);
const commentsOffset = ref(0);
const hideLoadMoreComments = ref(false);
const replyToUser = ref(null);
const editCommentData = ref(null);
const isCommentReplyView = ref(false);
const editorId = ref("mobile-comment-textarea");
const activeCommentPrivacy = ref("instructorOnly");
const activeCommentPrivacyDropdownValue = ref({
  label: "Instructor Only",
  key: "instructorOnly",
  icon: renderIcon(Lock01Icon),
});
const lockPrivacy = ref(false);
const editorInstance = ref(null);
const editPrivacyOpen = ref(false);
const commentPrivacyOptions = ref([
  {
    label: "Public",
    key: "publicAndInstructor",
    alternateKey: "public",
    icon: renderIcon(Globe01Icon),
  },
  {
    label: "Instructor Only",
    key: "instructorOnly",
    alternateKey: "private",
    icon: renderIcon(Lock01Icon),
  },
]);
const bottomCommentInputRef = ref<HTMLElement | null>(null);
const bottomCommentHeight = ref<number | null>(null);
const lastCommentRequestTime = ref<string | null>(null);
const deletingComment = ref(false);
onMounted(async () => {
  lastCommentRequestTime.value = new Date().toISOString();
  await fetchComments();
  updateRedirectToPost();
  if (productCommentPrivacy.value === "public") {
    activeCommentPrivacy.value = productCommentPrivacy.value;
  }
});

const editorConfig = computed(() => {
  return {
    ...mobileCommentEditorConfig,
  };
});

const productId = computed(() => props?.product?.id);
const postId = computed(() => props?.activePost?.id);
const categoryId = computed(() => props?.category?.id);
const userAvatar = computed(() => props?.instructor?.avatar || userAvatarImage);

const commentListEle = ref(null);

const currentCommentLength = computed(() => {
  return userComment.value.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, " ").length;
});

const showPostButton = computed(() => {
  return (
    currentCommentLength.value && currentCommentLength.value <= commentMaxLength.value
  );
});
const filteredPrivacyOptions = computed(() => {
  const privacyValue = productCommentPrivacy.value;
  const options = commentPrivacyOptions.value;

  if (!privacyValue) {
    // Return only instructor-only options if privacyValue is not set
    return options.filter((option: any) => option?.key === "instructorOnly");
  }

  if (privacyValue === "publicAndInstructor") {
    // Return all options for public and instructor visibility
    return options;
  }

  // Return options matching the specific privacy value
  return options.filter((option: any) => option?.key === privacyValue);
});

const productCommentPrivacy = computed(() => {
  return props?.product?.commentPrivacy;
});


const mobileTheme = computed(() => {
  return getMobileTheme();
});

function getEditorInstance(instance: any) {
  editorInstance.value = instance;
}

async function fetchComments(commentId?: string, overWrite = false, loadMore = false) {
  loadMore ? loadMoreFetching.value = true : fetching.value = true;
  try {
    const { data } = await CommentService.findComments({
      postId: postId.value,
      productId: productId.value,
      limit: commentId ? 1 : commentsLimit.value,
      offset: commentId ? 0 : commentsOffset.value,
      order: -1,
      commentId: commentId ? commentId : "",
      commentsAfter: lastCommentRequestTime.value ?? undefined,
    });

    if (!data.comments.length) {
      hideLoadMoreComments.value = true;
      return;
    }
    comments.value = overWrite ? data.comments : comments.value.concat(data.comments);
    commentsOffset.value += commentsLimit.value;
  } catch (error) {
    console.error("Error while fetching comments --> ", error);
  } finally {
    loadMore ? loadMoreFetching.value = false : fetching.value = false;
  }
}

async function createComment() {
  if (!userComment.value) {
    return;
  }
  posting.value = true;
  try {
    const { data } = await CommentService.createComment({
      postId: postId.value,
      productId: productId.value,
      content: userComment.value,
      visibility:
        activeCommentPrivacy.value === "instructorOnly"
          ? CommentVisibility.private
          : CommentVisibility.public,
      pendoPayload: {
        postTitle: props?.activePost?.title,
        productTitle: props?.product?.title,
        categoryTitle: props?.category?.title,
      },
    });

    if (data.error) {
      throw data.error;
    }

    addNewCommentToLocalState({
      ...data.comment,
      replies: [],
    });
    eraseWrittenComment();
    removeEditorFocus();
    if (commentListEle.value.scrollTop) commentListEle.value.scrollTop = 0;
  } catch (error) {
    console.error("Error while creating comment --> ", error);
  } finally {
    posting.value = false;
  }
}

async function updateComment() {
  posting.value = true;
  const commentId = editCommentData.value.id;
  try {
    const visibility =
      activeCommentPrivacy.value === "instructorOnly"
        ? CommentVisibility.private
        : CommentVisibility.public;

    await CommentService.updateComment(commentId, {
      content: userComment.value,
      visibility,
      pendoPayload: {
        postTitle: props?.activePost?.title,
        productTitle: props?.product?.title,
        categoryTitle: props?.category?.title,
      },
    });
    updateCommentInLocalState({
      id: commentId,
      content: userComment.value,
      visibility,
    });
    eraseWrittenComment();
    removeEditorFocus();
  } catch (error) {
    console.error("Error while updating comment --> ", error);
  } finally {
    posting.value = false;
    lockPrivacy.value = false;
  }
}

async function deleteComment(commentId: string) {
  try {
    deletingComment.value = true;
    await CommentService.deleteComment(commentId);
    deleteCommentFromLocalState(commentId);
    if (
      isCommentReplyView.value &&
      replyToUser.value &&
      commentId === replyToUser.value.commentId
    ) {
      await showAllComments();
    }
  } catch (error) {
    console.error("Error while deleting comment --> ", error);
  } finally {
    deletingComment.value = false;
  }
}

function updateCommentInLocalState(commentData: any) {
  const { id: commentId } = commentData;
  comments.value = comments.value.map((comment) => {
    if (comment.id !== commentId && !comment.replies.length) return comment;

    if (comment.id !== commentId) {
      comment.replies = comment.replies.map((reply) => {
        if (reply.id === commentId) {
          return {
            ...reply,
            content: commentData.content,
            visibility: commentData.visibility,
          };
        }
        return reply;
      });

      return comment;
    }
    return {
      ...comment,
      content: commentData.content,
      visibility: commentData.visibility,
    };
  });
}

function deleteCommentFromLocalState(commentId: string) {
  comments.value = comments.value.filter((comment) => {
    if (comment.id !== commentId && !comment.replies.length) return true;

    if (comment.id !== commentId) {
      comment.replies = comment.replies.filter((reply) => {
        if (reply.id === commentId) {
          return false;
        }
        return true;
      });

      return true;
    }
    return false;
  });
}

function addNewCommentToLocalState(newComment: any, isReply = false) {
  if (isReply) {
    const commentIndex = comments.value.findIndex(
      (cmt) => cmt.id === replyToUser.value.parentCommentId
    );

    if (commentIndex > -1) {
      comments.value[commentIndex].replies.push(newComment)
    }
    return
  }

  comments.value.unshift(newComment);
}

function eraseWrittenComment() {
  editCommentData.value = null;
  replyToUser.value = null;
  isCommentReplyView.value = false;
  userComment.value = "";
  editorInstance.value.commands?.setContent("");
  lockPrivacy.value = false
}

function showAllComments() {
  return fetchComments(undefined, true);
}

function removeEditorFocus() {
  // editorInstance.value?.removeFocus();
}

function updateRedirectToPost() {
  emit("updateBackData", {
    iconName: "chevronLeft",
    iconType: "dark",
    text: props?.activePost?.title,
    subtext:
      props?.activePost?.title.length > MOBILE_HEADING_MAX_LENGTH
        ? props?.activePost?.title.substring(0, MOBILE_HEADING_MAX_LENGTH) + "..."
        : props?.activePost?.title,
    theme: mobileTheme.value,
  });
}

function updateCommentPrivacy(value: string) {
  if (lockPrivacy.value || !value) return;
  activeCommentPrivacy.value = value;
  activeCommentPrivacyDropdownValue.value = commentPrivacyOptions.value.find(
    (option) => (option?.key === value || option?.alternateKey === value)
  );

}

async function updateReplyToUser(user, eraseContent = true, extraConfig) {
  lockPrivacy.value = false;

  if (replyToUser.value && replyToUser.value.commentId === user.commentId) return;
  if (eraseContent) eraseWrittenComment();

  replyToUser.value = user ? user : null;

  if (extraConfig && extraConfig.skipNameTag) return;

  userComment.value = user.name ? `@${user.name.replace(" ", "")} ` : "";

  editorInstance.value.commands?.insertContent(
    user.name ? `@${user.name.replace(" ", "")} ` : ""
  );
  editorInstance.value.commands?.focus();

  if (productCommentPrivacy.value !== "publicAndInstructor") {
    updateCommentPrivacy(productCommentPrivacy.value);
  } else {
    updateCommentPrivacy(
      user.visibility === CommentVisibility.private ? "instructorOnly" : user.visibility
    );
  }
  lockPrivacy.value = true;
}

async function replyToComment() {
  if (!userComment.value) return;

  posting.value = true;
  try {
    const { data } = await CommentService.createComment({
      postId: postId.value,
      productId: productId.value,
      content: userComment.value,
      parentCommentId: replyToUser.value?.parentCommentId,
      visibility:
        activeCommentPrivacy.value === "instructorOnly"
          ? CommentVisibility.private
          : CommentVisibility.public,
    });

    if (data.error) throw data.error;

    addNewCommentToLocalState(data.comment, true);
    eraseWrittenComment();
    removeEditorFocus();
  } catch (error) {
    console.error("Error while replying to comment --> ", error);
  } finally {
    posting.value = false;
    lockPrivacy.value = false;
  }
}

function updateEditComment(commentData) {
  eraseWrittenComment();
  editCommentData.value = commentData ? commentData : null;
  userComment.value = commentData ? commentData.content : "";

  editorInstance.value.commands?.insertContent(userComment.value);
  editorInstance.value.commands?.focus()
  if (productCommentPrivacy.value !== "publicAndInstructor") {
    updateCommentPrivacy(productCommentPrivacy.value);
  } else {
    updateCommentPrivacy(
      commentData.visibility === CommentVisibility.private
        ? "instructorOnly"
        : commentData.visibility
    );
  }
  if (commentData.repliedToUserId) {
    lockPrivacy.value = true;
  }
}

function getPostFunction() {
  if (!showPostButton.value || !currentCommentLength.value || posting.value) {
    return null;
  }
  return replyToUser.value
    ? replyToComment()
    : editCommentData.value
      ? updateComment()
      : createComment();
}


const getBottomCommentHeight = () => {
  if (bottomCommentInputRef.value) {
    bottomCommentHeight.value = bottomCommentInputRef.value.offsetHeight;
  }
};

function handleEditPrivacyToggle() {
  if (lockPrivacy.value) return;

  editPrivacyOpen.value = !editPrivacyOpen.value
}

function handleEditPrivacyClick(key) {
  updateCommentPrivacy(key);
  handleEditPrivacyToggle()

}

//?? This will adjust the comment container height based on the height of the bottom comment input
watch(userComment, debounce(() => {
  getBottomCommentHeight();
}));

onMounted(() => {
  getBottomCommentHeight();

});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (fetching.value)
      ? (_openBlock(), _createBlock(CommentShimmerV2, { key: 0 }))
      : (comments.value && comments.value?.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "w-full h-full flex flex-col items-start overflow-y-scroll",
            style: _normalizeStyle({ height: `calc(100% - ${bottomCommentHeight.value}px` }),
            ref_key: "commentListEle",
            ref: commentListEle
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value, (comment) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full",
                key: comment.id
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(CommentV2, {
                    userAvatar: userAvatar.value,
                    comment: comment,
                    instructor: __props.instructor,
                    activePost: __props.activePost,
                    product: __props.product,
                    category: __props.category,
                    deletingComment: deletingComment.value,
                    isRepliesAvailable: comment?.replies && comment?.replies.length > 0,
                    onUpdateReplyToUser: updateReplyToUser,
                    onUpdateEditComment: updateEditComment,
                    onDeleteComment: deleteComment,
                    visibility: comment?.visibility,
                    repliedComment: replyToUser.value?.commentId
                  }, null, 8, ["userAvatar", "comment", "instructor", "activePost", "product", "category", "deletingComment", "isRepliesAvailable", "visibility", "repliedComment"])
                ])
              ]))
            }), 128)),
            (!hideLoadMoreComments.value && comments.value.length >= commentsLimit.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "w-full text-center mb-4 text-[--accent-color] clickable",
                  onClick: _cache[0] || (_cache[0] = () => fetchComments('', false, true))
                }, [
                  (loadMoreFetching.value)
                    ? (_openBlock(), _createBlock(CommentShimmerV2, {
                        key: 0,
                        count: 1
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3, "Load more comments"))
                ]))
              : _createCommentVNode("", true)
          ], 4))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "w-full h-full flex flex-col items-start overflow-y-scroll",
            style: _normalizeStyle({ height: `calc(100% - ${bottomCommentHeight.value}px` }),
            ref_key: "commentListEle",
            ref: commentListEle
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "w-full h-full flex items-center justify-center" }, [
              _createElementVNode("span", { class: "text-gray-400 text-sm font-medium" }, "Add first comment to this lesson! ")
            ], -1)
          ]), 4)),
    _createElementVNode("div", {
      class: "bottom-comment-input w-full absolute bottom-0 bg-white pt-2",
      ref_key: "bottomCommentInputRef",
      ref: bottomCommentInputRef
    }, [
      _createElementVNode("div", _hoisted_4, [
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-[#909090] text-xs font-normal" }, "Who can read your comments?", -1)),
        _createElementVNode("span", {
          class: _normalizeClass(["flex items-center gap-1 text-[--accent-color]", lockPrivacy.value ? 'text-gray-400 border-gray-400' : '']),
          onClick: handleEditPrivacyToggle
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(activeCommentPrivacyDropdownValue.value?.icon), { class: "h-3 w-3" })),
          _createTextVNode(" " + _toDisplayString(activeCommentPrivacyDropdownValue.value?.label) + " ", 1),
          _createVNode(_unref(ChevronUpIcon), { class: "h-6 w-6" })
        ], 2)
      ]),
      (replyToUser.value?.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(`Reply to ${replyToUser.value.name}`), 1),
            _createVNode(_unref(XIcon), {
              class: "h-6 w-6 text-[--accent-color]",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (eraseWrittenComment()))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(Editor, {
          "desktop-screen": false,
          id: editorId.value,
          editorText: userComment.value,
          placeholder: replyToUser.value ? `Reply to ${replyToUser.value.name}` : 'Type your comment',
          init: { selector: '#' + editorId.value, ...editorConfig.value },
          onGetInstance: getEditorInstance,
          class: "w-full border-0",
          "onUpdate:editorText": _cache[2] || (_cache[2] = ($event: any) => (userComment.value = $event))
        }, null, 8, ["id", "editorText", "placeholder", "init"]),
        _createElementVNode("span", {
          class: _normalizeClass(["text-xs text-gray-300 font-medium min-w-16", { 'text-red-500': currentCommentLength.value > commentMaxLength.value }])
        }, _toDisplayString(currentCommentLength.value) + " / " + _toDisplayString(commentMaxLength.value), 3),
        (posting.value)
          ? (_openBlock(), _createBlock(UISpinner, {
              key: 0,
              class: "min-h-6 min-w-6 flex-shrink-0 text-[--accent-color]"
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass({ 'min-w-10': editCommentData.value, 'min-w-6': !editCommentData.value })
            }, [
              (editCommentData.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["h-6 ml-2 text-[--accent-color]", { 'text-gray-300': currentCommentLength.value > commentMaxLength.value }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (getPostFunction()))
                  }, "Save", 2))
                : (_openBlock(), _createBlock(_unref(Send01Icon), {
                    key: 1,
                    class: _normalizeClass(["min-h-6 min-w-6 flex-shrink-0 text-[--accent-color]", { 'text-gray-300': currentCommentLength.value > commentMaxLength.value }]),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (getPostFunction()))
                  }, null, 8, ["class"]))
            ], 2)),
        _createVNode(DrawerMenuV2, {
          placement: 'bottom',
          drawerOpen: editPrivacyOpen.value,
          drawerMenuOptions: filteredPrivacyOptions.value,
          handleDrawerMenuClick: handleEditPrivacyClick,
          handleDrawerMenuToggle: handleEditPrivacyToggle
        }, null, 8, ["drawerOpen", "drawerMenuOptions"])
      ])
    ], 512)
  ]))
}
}

})