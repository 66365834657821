import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "post-details md:grid grid-cols-12 gap-4 mt-4" }
const _hoisted_2 = { class: "video-post-body-container col-span-8" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = {
  id: "post-body",
  class: "block bg-white p-5 shadow rounded lesson-body-background col-span-8"
}
const _hoisted_6 = {
  key: 0,
  class: "font-semibold post-body-title lessonPrimaryStyle lesson-body-title"
}
const _hoisted_7 = {
  key: 1,
  class: "p-5 justify-center"
}
const _hoisted_8 = { class: "flex justify-center" }
const _hoisted_9 = {
  key: 2,
  class: "p-5 justify-center"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 4,
  class: "post-status-fetch-message"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = {
  key: 0,
  class: "w-full"
}
const _hoisted_14 = { class: "post-playlist-container col-span-4" }
const _hoisted_15 = { class: "post-materials-container block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryPostBreadcrumbs = _resolveComponent("CategoryPostBreadcrumbs")!
  const _component_video_player = _resolveComponent("video-player")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_QuizStatusComponent = _resolveComponent("QuizStatusComponent")!
  const _component_AssignmentStatus = _resolveComponent("AssignmentStatus")!
  const _component_go_to_next_post = _resolveComponent("go-to-next-post")!
  const _component_CommentList = _resolveComponent("CommentList")!
  const _component_post_queue = _resolveComponent("post-queue")!
  const _component_PostMaterialsCard = _resolveComponent("PostMaterialsCard")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!

  return (_openBlock(), _createElementBlock("div", {
    id: "post-details-container",
    class: _normalizeClass(
      _ctx.disableActions && _ctx.disableActions.pointerEvents === 'none' ? 'px-2' : ''
    )
  }, [
    _createVNode(_component_CategoryPostBreadcrumbs, {
      "product-id": _ctx.productId,
      "product-title": _ctx.product && _ctx.product.title,
      "category-id": _ctx.categoryId,
      "category-title": _ctx.category && _ctx.category.title,
      "post-id": _ctx.postId,
      "post-title": _ctx.activePost && _ctx.activePost.title
    }, null, 8, ["product-id", "product-title", "category-id", "category-title", "post-id", "post-title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.activePost.video && _ctx.videoOptions)
            ? (_openBlock(), _createBlock(_component_video_player, {
                key: 0,
                id: "post-video",
                ref: "videoContainer",
                class: "player-height mb-4",
                loading: _ctx.fetchingPost,
                video: _ctx.activePost && _ctx.activePost.video,
                assetUrl: _ctx.assetUrls,
                "video-thumbnail": _ctx.activePost.posterImage,
                options: _ctx.videoOptions,
                trackTime: true,
                videoCompletePercentage: _ctx.videoCompletePercentage,
                onGetVideoTime: _ctx.getVideoTime,
                onOnPause: _ctx.storeVideoProgress,
                onOnEnded: _ctx.storeVideoProgress,
                onOnPlay: _ctx.updateVideoPlay
              }, null, 8, ["loading", "video", "assetUrl", "video-thumbnail", "options", "videoCompletePercentage", "onGetVideoTime", "onOnPause", "onOnEnded", "onOnPlay"]))
            : (_ctx.activePost.audio)
              ? (_openBlock(), _createBlock(_component_audio_player, {
                  key: 1,
                  id: "post-audio",
                  audio: _ctx.activePost.audio,
                  thumbnail: _ctx.activePost.posterImage,
                  post: _ctx.activePost,
                  product: _ctx.product,
                  onGetAudioTime: _ctx.getAudioTime,
                  onOnPause: _ctx.storeAudioProgress,
                  onOnEnded: _ctx.storeAudioProgress,
                  onOnPlay: _ctx.updateAudioPlay
                }, null, 8, ["audio", "thumbnail", "post", "product", "onGetAudioTime", "onOnPause", "onOnEnded", "onOnPlay"]))
              : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (
                _ctx.currentPostContentType === _ctx.postContentType.video ||
                _ctx.currentPostContentType === _ctx.postContentType.audio
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "ABOUT THIS LESSON"))
              : _createCommentVNode("", true),
            (_ctx.currentPostContentType === _ctx.postContentType.quiz)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_QuizStatusComponent, {
                      post: _ctx.activePost,
                      categoryId: _ctx.categoryId,
                      onChangeQuizStatus: _ctx.changeQuizStatus
                    }, null, 8, ["post", "categoryId", "onChangeQuizStatus"])
                  ])
                ]))
              : (_ctx.currentPostContentType === _ctx.postContentType.assignment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_AssignmentStatus, {
                        post: _ctx.activePost,
                        categoryId: _ctx.categoryId,
                        showRetakeButton: true,
                        nextPost: _ctx.nextPost,
                        completed: _ctx.completedPostIds.has(_ctx.nextPost.id)
                      }, null, 8, ["post", "categoryId", "nextPost", "completed"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    id: "post-description",
                    class: "lessonSecondaryStyle lesson-body-description custom-word-break",
                    innerHTML: _ctx.activePost.description
                  }, null, 8, _hoisted_10)),
            (_ctx.fetchingPostCompletionStatus)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Fetching post status.. "))
              : (_openBlock(), _createElementBlock("button", {
                  key: 5,
                  id: "post-completion-button",
                  disabled: _ctx.disablePostCompletionButton || _ctx.isAdmin,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePostCompletion())),
                  class: _normalizeClass(["mt-5 w-full bg-transparent font-semibold py-2 px-4 border rounded lessonPrimaryStyle", [
                _ctx.markCompletedBtnCss,
                _ctx.isAdmin ? 'opacity-75 cursor-not-allowed' : '',
              ]])
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.postCompletionButtonText), 1)
                ], 10, _hoisted_12)),
            (_ctx.nextPost && _ctx.completedPostIds.has(_ctx.postId))
              ? (_openBlock(), _createBlock(_component_go_to_next_post, {
                  key: 6,
                  class: "mt-4",
                  post: _ctx.nextPost,
                  "next-lesson": _ctx.nextLesson,
                  completed: _ctx.completedPostIds.has(_ctx.nextPost.id)
                }, null, 8, ["post", "next-lesson", "completed"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.activePost.commentPermission !== 'hidden')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_CommentList, {
                productCommentPrivacy: _ctx.product.commentPrivacy,
                postId: _ctx.postId,
                productId: _ctx.productId,
                instructor: _ctx.instructor,
                commentPermission: _ctx.activePost.commentPermission,
                pendoPayload: { post: _ctx.activePost, product: _ctx.product, category: _ctx.category },
                commentIdToScroll: _ctx.commentIdToScroll
              }, null, 8, ["productCommentPrivacy", "postId", "productId", "instructor", "commentPermission", "pendoPayload", "commentIdToScroll"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.playlist && _ctx.playlist.length)
          ? (_openBlock(), _createBlock(_component_post_queue, {
              key: 0,
              id: "post-playlist",
              class: "player-height postqueue-background grid grid-rows-post-playlist mb-4 min-h-60",
              style: _normalizeStyle(`max-height: ${_ctx.maxHeightOfPlaylist}px; ${
            !_ctx.activePost.video && `min-height: ${_ctx.maxHeightOfPlaylist}px`
          }`),
              "product-id": _ctx.productId,
              "category-id": _ctx.categoryId,
              "category-title": _ctx.category.title,
              playlist: _ctx.playlist,
              "active-post": _ctx.postId,
              "completed-posts": _ctx.completedPostIds,
              "next-category": _ctx.nextCategoryId
            }, null, 8, ["style", "product-id", "category-id", "category-title", "playlist", "active-post", "completed-posts", "next-category"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.activePost.materials && _ctx.activePost.materials.length)
            ? (_openBlock(), _createBlock(_component_PostMaterialsCard, {
                key: 0,
                id: "post-materials",
                class: "mb-5",
                materials: _ctx.activePost.materials
              }, null, 8, ["materials"]))
            : _createCommentVNode("", true),
          _createVNode(_component_InstructorCard, { instructor: _ctx.instructor }, null, 8, ["instructor"])
        ])
      ])
    ])
  ], 2))
}