import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "fixed z-10 inset-0 overflow-y-auto pt-10",
  "aria-labelledby": "modal-title",
  role: "dialog",
  "aria-modal": "true"
}
const _hoisted_2 = { class: "flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_3 = { class: "ui-modal inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-40 my-8 lg:align-middle md:align-middle w-11/12 lg:w-2/5 md:w-2/3" }
const _hoisted_4 = { class: "flex flex-col bg-white py-2 sm:p-6 sm:pb-4" }
const _hoisted_5 = { class: "sm:flex sm:items-start" }
const _hoisted_6 = { class: "w-full text-center sm:text-left h-16" }
const _hoisted_7 = { class: "flex border-b border-gray-500 px-2" }
const _hoisted_8 = { class: "py-1 mt-2" }
const _hoisted_9 = { class: "w-full" }
const _hoisted_10 = {
  key: 0,
  id: "searchList"
}
const _hoisted_11 = {
  key: 0,
  id: "optionList",
  class: "block py-2"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "p-2 text-left flex justify-between" }
const _hoisted_14 = { class: "text-gray-900 text-sm" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = {
  key: 0,
  class: "px-4 py-2 flex justify-center items-center text-gray-700",
  ref: "loadMore"
}
const _hoisted_18 = { class: "inline-flex h-6 w-6" }
const _hoisted_19 = {
  key: 1,
  class: "block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700"
}
const _hoisted_20 = { class: "font-semibold" }
const _hoisted_21 = {
  key: 1,
  class: "p-2 text-gray-700"
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "py-1 px-2 text-left" }
const _hoisted_24 = {
  key: 2,
  class: "p-2 text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileSearch = _resolveComponent("MobileSearch")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.newMobileScreens && _ctx.activeLibrary)
      ? (_openBlock(), _createBlock(_component_MobileSearch, {
          key: 0,
          searchFullText: _ctx.searchFullText,
          searchContent: _ctx.searchContent,
          resetData: _ctx.resetData,
          fetchProductTitle: _ctx.fetchProductTitle,
          changeRoute: _ctx.changeRoute,
          searchCount: _ctx.searchCount,
          nextSearchData: _ctx.nextSearchData
        }, null, 8, ["searchFullText", "searchContent", "resetData", "fetchProductTitle", "changeRoute", "searchCount", "nextSearchData"]))
      : (_ctx.openModal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("div", {
                class: "fixed inset-0 bg-gray-900 opacity-75 transition-opacity",
                "aria-hidden": "true"
              }, null, -1)),
              _cache[7] || (_cache[7] = _createElementVNode("span", {
                class: "hidden sm:inline-block sm:align-middle sm:h-screen",
                "aria-hidden": "true"
              }, "​", -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "h-1.5 p-2 md:px-0 lg:px-0 cursor-pointer pb-4",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                  }, [
                    _createVNode(_component_BaseIcon, {
                      name: "cross",
                      hwClass: "w-4 h-4 float-right"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_BaseIcon, {
                            name: "search",
                            hwClass: "w-5 h-5"
                          })
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          id: "search",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchText) = $event)),
                          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createDebounce())),
                          "aria-label": "Search",
                          name: "search",
                          type: "text",
                          maxlength: "64",
                          class: "appearance-none rounded-none relative block w-full focus:outline-none placeholder-gray-600 text-gray-900 sm:text-base sm:leading-5 placeholder:text-base placeholder:leading-5 pl-4",
                          placeholder: "Search products, categories and lessons",
                          style: { 'text-align-last': 'start' }
                        }, null, 544), [
                          [
                            _vModelText,
                            _ctx.searchText,
                            void 0,
                            { trim: true }
                          ]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        (_ctx.searchText)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              (_ctx.searchData.length || _ctx.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchData, (option) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "searchList flex flex-col my-2 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-300 leading-5",
                                        key: option.typeId,
                                        onClick: _withModifiers(($event: any) => (_ctx.changeRoute(option)), ["prevent"])
                                      }, [
                                        _createElementVNode("div", _hoisted_13, [
                                          _createElementVNode("span", _hoisted_14, _toDisplayString(option.title.length > 50
                              ? `${option.title.substring(0, 40)}...`
                              : option.title), 1),
                                          _createElementVNode("img", {
                                            class: "text-gray-700 h-5",
                                            src: _ctx.searchIcons[option.type],
                                            alt: "product icon"
                                          }, null, 8, _hoisted_15)
                                        ]),
                                        (option.description)
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              innerHTML: option.description,
                                              class: "px-2 text-left text-xs h-auto"
                                            }, null, 8, _hoisted_16))
                                          : _createCommentVNode("", true),
                                        (option.type !== 'product')
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 1,
                                              class: _normalizeClass(["product-title px-2 text-left font-semibold text-xs h-5", option.description ? 'p-2' : 'pb-2'])
                                            }, _toDisplayString(_ctx.productTitleMapping[option.productId]), 3))
                                          : _createCommentVNode("", true)
                                      ], 8, _hoisted_12))
                                    }), 128)),
                                    (!_ctx.allDataLoaded || _ctx.loading)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                          _createElementVNode("span", _hoisted_18, [
                                            _createVNode(_component_UISpinner, { size: "small" })
                                          ])
                                        ], 512))
                                      : _createCommentVNode("", true)
                                  ]))
                                : (!_ctx.isSearchDataAvailable)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                      _cache[3] || (_cache[3] = _createTextVNode(" No results for ")),
                                      _createElementVNode("span", _hoisted_20, "\"" + _toDisplayString(_ctx.searchText) + "\"", 1),
                                      _cache[4] || (_cache[4] = _createTextVNode(". Please try another search "))
                                    ]))
                                  : _createCommentVNode("", true)
                            ]))
                          : (_ctx.recentSearches.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-left" }, "Recent Searches", -1)),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentSearches, (option, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "searchList flex flex-col my-2 pr-6 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-300 leading-5",
                                    key: index,
                                    onClick: _withModifiers(
                        () => {
                          _ctx.searchText = option
                          _ctx.searchContent()
                        }
                      , ["prevent"])
                                  }, [
                                    _createElementVNode("div", _hoisted_23, _toDisplayString(option), 1)
                                  ], 8, _hoisted_22))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_24, "No Recent Searches"))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
  ]))
}