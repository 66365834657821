import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]

import { UIDrawer, UIDrawerContent } from '@gohighlevel/ghl-ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerMenuV2',
  props: {
    drawerOpen: Boolean,
    drawerMenuOptions: Array<any>,
    handleDrawerMenuClick: Function,
    handleDrawerMenuToggle: Function,
    placement: String
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UIDrawer), {
    show: __props.drawerOpen,
    placement: __props.placement,
    id: 'drawer',
    displayDirective: 'if',
    "default-height": "20vh",
    "on-mask-click": __props.handleDrawerMenuToggle
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(UIDrawerContent), {
        id: "drawer-component",
        class: "p-4",
        closable: false
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.drawerMenuOptions, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.key,
              class: _normalizeClass(["w-full p-3 flex gap-4 items-center text-sm font-medium text-gray-500", option.color]),
              onClick: ($event: any) => (__props.handleDrawerMenuClick(option.key))
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(option?.icon), {
                class: "border rounded border-gray-50 bg-gray-50 p-2",
                style: { height: '40px', width: '40px' }
              })),
              _createTextVNode(" " + _toDisplayString(option.label), 1)
            ], 10, _hoisted_1))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "placement", "on-mask-click"]))
}
}

})