import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-11/12 flex flex-col items-start mx-auto gap-2 my-4"
}
const _hoisted_2 = { class: "inline m-0" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "w-11/12 flex flex-col items-start mx-auto gap-2 my-4" }
const _hoisted_6 = { class: "font-semibold text-[#101828] text-base break-all line-clamp-1" }
const _hoisted_7 = { class: "flex items-center gap-4 my-2 w-full" }
const _hoisted_8 = { class: "flex flex-col items-start gap-2" }
const _hoisted_9 = { class: "font-semibold text-sm text-[#101828] break-all line-clamp-1" }
const _hoisted_10 = { class: "text-[#667085] text-xs break-all line-clamp-1" }
const _hoisted_11 = { class: "text-[#667085] w-full break-all" }

import { sanitizeJavaScriptCode } from "@/helper";
import { INSTRUCTOR_DEFAULT_VALUES, MAX_DESCRIPTION_CHARACTERS, userAvatarImage } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import Product from "@/models/Product";
import { UIAvatar } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryOverviewV2',
  props: {
    product: {}
  },
  setup(__props: any) {

const props = __props;


const isExpanded = ref(false);

const needsViewMore = computed(() => {
  return props.product?.description && sanitizeJavaScriptCode(props.product?.description).length > MAX_DESCRIPTION_CHARACTERS;
});

const getDescriptionClass = computed(() => {
  return {
    "!max-h-20 line-clamp-3 overflow-y-hidden": !isExpanded.value && needsViewMore.value,
  };
});

const toggleView = () => {
  isExpanded.value = !isExpanded.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.product.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-semibold text-[#101828] text-base" }, "Course description", -1)),
          _createElementVNode("p", _hoisted_2, [
            _createElementVNode("span", {
              class: _normalizeClass(["text-[#667085] text-xs font-normal post-description mobile-post-details", getDescriptionClass.value]),
              innerHTML: _ctx.product.description
            }, null, 10, _hoisted_3),
            (needsViewMore.value && !isExpanded.value)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: toggleView,
                  class: "text-blue-500 font-semibold text-sm cursor-pointer"
                }, " View More "))
              : _createCommentVNode("", true),
            (needsViewMore.value && isExpanded.value)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: toggleView,
                  class: "text-blue-500 font-semibold text-sm cursor-pointer"
                }, " View Less "))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.product.description)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.product.customizations?.instructorHeading || _unref(INSTRUCTOR_DEFAULT_VALUES).title), 1),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(UIAvatar), {
          size: 'large',
          objectFit: 'fill',
          round: true,
          class: "min-h-10 min-w-10",
          src: _unref(replaceBucketUrlWithCdnUrl)(_ctx.product.customizations?.instructorHeadshot) || _unref(INSTRUCTOR_DEFAULT_VALUES).headshot
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.product.customizations?.instructorName || _unref(INSTRUCTOR_DEFAULT_VALUES).name), 1),
          _createElementVNode("span", _hoisted_10, _toDisplayString((_ctx.product.customizations?.instructorTitle) ||
              _unref(INSTRUCTOR_DEFAULT_VALUES).heading), 1)
        ])
      ]),
      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.product.customizations?.instructorBio || _unref(INSTRUCTOR_DEFAULT_VALUES).bio), 1)
    ])
  ]))
}
}

})