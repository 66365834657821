import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-w-xl mx-auto lg:max-w-auto" }
const _hoisted_2 = { class: "pt-4 px-4 pb-4 text-center" }
const _hoisted_3 = { class: "inline-block relative align-start text-left bg-white transform transition-all w-full" }
const _hoisted_4 = { class: "text-center mt-2 p-2" }
const _hoisted_5 = { class: "block px-2 font-semibold" }
const _hoisted_6 = { class: "flex flex-col py-2" }
const _hoisted_7 = { class: "px-2 flex flex-col items-start mb-4" }
const _hoisted_8 = { class: "relative offer-poster-image w-full py-2" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: "absolute top-0 right-1 mx-3 my-3 px-1 rounded text-gray-900 text-xs",
  style: {"background-color":"#ffbc00"}
}
const _hoisted_11 = { class: "flex flex-col w-full" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 1,
  class: "px-2 my-4 text-center text-base"
}
const _hoisted_14 = { class: "flex flex-col justify-center mb-4" }
const _hoisted_15 = { class: "pl-2 pr-2 text-center text-xs flex-1" }
const _hoisted_16 = {
  key: 0,
  id: "offer-price-display-override"
}
const _hoisted_17 = {
  key: 1,
  id: "offer-amount"
}
const _hoisted_18 = {
  id: "bundle-products",
  class: "mx-2 grid grid-cols-2 gap-4 justify-center"
}
const _hoisted_19 = {
  key: 0,
  id: "payment-form",
  class: "px-5"
}

import { ref, computed, onBeforeMount, onMounted, watch, getCurrentInstance, inject } from 'vue'
import { defaultPosterImage } from '@/helper/constants'
import CheckoutService from '@/services/CheckoutService'
import NewProductCard from '@/components/product/NewProductCard.vue'
import { getContactId } from '@/services'
import { OfferType, CheckoutSourceType } from '@/models/Offer'
import { UpsellOfferType } from '@/models/UpsellOffer'
import { adminMode } from '@/helper/permission.helper'
import { fetchCurrencySymbol, replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import UISpinner from '@/components/common/UISpinner.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { UserPurchaseService } from '@/services'
import { Contacts, Stripe } from '@/helper/restAgent'
import Navigation from '@/components/common/Navigation.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PaymentModal from '@/components/checkout/PaymentModal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileUpsellBundlePage',
  setup(__props) {

const offer = ref({})
const contact = ref<{ [key: string]: any }>({})
const showModal = ref(false)
const showThankYouMsg = ref(false)
const location = ref({})
const loader = ref(false)
const disableCheckoutBtn = ref(false)
const paymentMethods = ref<any[]>([])
const purchasedProductIds = ref<any[]>([])
const offerAlreadyPurchased = ref(false)
const optin = ref(false)
const route = useRoute()
const store = useStore()
const router = useRouter()
const emitter = inject('emitter')


const freeProduct = computed(() => offer.value.type === OfferType.free)
const showPaymentForm = computed(() => !optin.value && !showThankYouMsg.value && !freeProduct.value && !offerAlreadyPurchased.value)
const isAdmin = computed(() => adminMode())
const offerDescription = computed(() => offer.value.description ? (offer.value.description.length > 130 ? offer.value.description.substring(0, 130) + '...' : offer.value.description) : '')
const offerId = computed(() => route.params.offerId as string)
const upsellId = computed(() => route.params.upsellId as string)
const device = computed(() => store.getters.device)
const newMobileScreens = computed(() => store.getters.newMobileScreens)
const locationId = computed(() => store.getters.locationId)

const fetchUserProducts = async () => {
  loader.value = true
  try {
    const products = await UserPurchaseService.getAllProducts()
    purchasedProductIds.value = products.map((p) => p.id)
  } catch (error) {
    console.error('Error while fetching products --> ', error)
  } finally {
    loader.value = false
  }
}

// Fetch offer data
const fetchOfferData = async () => {
  try {
    loader.value = true
    const contactId = getContactId()
    contact.value = await Contacts.getContactById(locationId.value, contactId)

    const response = await CheckoutService.findOfferById(offerId.value, contact.value.locationId, { productCustomization: true })
    if (response?.status === 200) {
      const { data } = response
      offer.value = data
      location.value = data.location
    }

    if (contact.value.stripeCustomerId) {
      await fetchPaymentMethods()
    }
  } catch (e) {
    console.error(e)
  } finally {
    loader.value = false
  }
}

// Fetch payment methods
const fetchPaymentMethods = async () => {
  try {
    const params = {
      stripeCustomerId: contact.value.stripeCustomerId,
      offerId: offerId.value,
    }
    const { data } = await Stripe.getPaymentMethod(locationId.value, params)
    paymentMethods.value = data
  } catch (error) {
    console.error(error)
  }
}

// Checkout logic
const checkout = async () => {
  if (isAdmin.value) return

  disableCheckoutBtn.value = true
  await checkContactPurchasedOffer()
  if (!optin.value && !showThankYouMsg.value && !freeProduct.value && !offerAlreadyPurchased.value) {
    showModal.value = true
  }
  disableCheckoutBtn.value = false
}

// Check if contact has purchased the offer
const checkContactPurchasedOffer = async () => {
  const { data: { purchased, loginUrl } } = await CheckoutService.checkIfContactPurchasedOffer({
    contactId: contact.value.id,
    offerId: offerId.value,
    locationId: offer.value.locationId,
  })
  offerAlreadyPurchased.value = purchased
  if (offerAlreadyPurchased.value) {
    // Handle if already purchased
    return
  }
  await createLeadEntry()
  if (offer.value.type === OfferType.free) {
    await createFreePurchase()
  }
}

// Create a free purchase
const createFreePurchase = async () => {
  const payload = {
    fingerprint: contact.value.fingerprint,
    locationId: contact.value.locationId,
    productType: 'offer',
    checkoutType: 'free',
  }

  try {
    const response = await CheckoutService.checkout(offerId.value, payload)
    showThankYouMsg.value = true
    const { ct, previewUrl } = response.data
    showModal.value = true
    // Handle success response
  } catch (error) {
    console.error(error)
  }
}

const goToPreview = () => {
  router.push({
    name: 'library-v2',
  })
}

const orderConfirmation = ({ ct, previewUrl, upsellOfferId }) => {
  showThankYouMsg.value = true
  // setTimeout(() => {
  //   window.location.reload()
  // }, 2000)
}

// Lead entry creation
const createLeadEntry = async () => {
  const payload = {
    contactId: contact.value.id,
    locationId: contact.value.locationId,
    offerId: offerId.value,
  }
  await CheckoutService.leadTrack(payload)
}

const backToLibrary = () => {
  router.push({ name: 'library-v2' })
}

const checkoutCallback = () => {
  emitter.emit('handleUpsellCheckout', upsellId.value)
}

// Lifecycle hooks
onBeforeMount(() => {
  if (!newMobileScreens.value || !offer.value) {
    router.push({ name: 'library-v2' })
  }
})

onMounted(async () => {
  await fetchUserProducts()
  await fetchOfferData()
})

// Watchers
watch(device, (newDevice) => {
  if (newDevice === 'mobile') {
    router.push({ name: 'library-v2' })
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Navigation, { backCallback: backToLibrary }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(offer.value.title), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                src: offer.value.posterImage ||
                _unref(replaceBucketUrlWithCdnUrl)(_unref(defaultPosterImage))
                ,
                class: _normalizeClass(["object-contain w-full rounded", !offer.value.posterImage ? 'h-28' : 'h-full'])
              }, null, 10, _hoisted_9),
              (offer.value.products && offer.value.products.length > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Bundle "))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              (offerDescription.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    innerHTML: offerDescription.value,
                    class: "px-2 text-base custom-list-styling"
                  }, null, 8, _hoisted_12))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, " No Description Available ")),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", {
                  onClick: checkoutCallback,
                  class: "p-2 text-center text-blue-600 text-xs cursor-pointer mb-2"
                }, " Learn More "),
                (loader.value)
                  ? (_openBlock(), _createBlock(UISpinner, {
                      key: 0,
                      color: "#4299e1",
                      class: "min-w-48 flex justify-center items-center p-2 mx-3",
                      size: "extra-small"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center rounded bg-blue-500 text-white font-semibold p-2 min-w-48 upsell__purchase-btn mx-3", [
                    loader.value ? 'hidden' : 'block',
                    !isAdmin.value && 'cursor-pointer',
                  ]]),
                  onClick: checkout
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-left pl-2 flex", {
                    'opacity-75 cursor-not-allowed': disableCheckoutBtn.value,
                  }])
                  }, [
                    (disableCheckoutBtn.value)
                      ? (_openBlock(), _createBlock(UISpinner, {
                          key: 0,
                          color: "#ffffff",
                          class: "pr-2",
                          size: "small"
                        }))
                      : (_openBlock(), _createBlock(BaseIcon, {
                          key: 1,
                          name: "cart",
                          hwClass: "w-5 text-white"
                        }))
                  ], 2),
                  _createElementVNode("span", _hoisted_15, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", null, "Enroll for ", -1)),
                    (offer.value.priceDisplayOverride)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(offer.value.priceDisplayOverride), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(fetchCurrencySymbol)(offer.value.currency)) + _toDisplayString(Number(offer.value.amount).toFixed(2)), 1))
                  ])
                ], 2)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offer.value.products, (product) => {
              return (_openBlock(), _createBlock(NewProductCard, {
                key: product.id,
                class: "bg-white",
                "product-id": product.id,
                "product-title": product.title,
                "poster-image": product.posterImage,
                "show-instructor-mobile": true,
                "instructor-name": product.customizations.instructorName,
                "instructor-headshot": product.customizations.instructorHeadshot,
                upsellProduct: true,
                label: purchasedProductIds.value.indexOf(product.id) !== -1
                  ? 'in-library'
                  : 'locked'
                  ,
                onHandleClick: checkoutCallback
              }, null, 8, ["product-id", "product-title", "poster-image", "instructor-name", "instructor-headshot", "label"]))
            }), 128))
          ])
        ])
      ])
    ]),
    (showPaymentForm.value || showThankYouMsg.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(PaymentModal, {
            showThankYouMsg: showThankYouMsg.value,
            freeProduct: freeProduct.value,
            contact: contact.value,
            "show-modal": showModal.value,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = false)),
            location: location.value,
            paymentMethods: paymentMethods.value,
            locationId: offer.value.locationId,
            offerId: offer.value.id,
            isUpsell: true,
            upsellType: _unref(UpsellOfferType).INAPP,
            offer: offer.value,
            checkoutSource: _unref(CheckoutSourceType).INAPP,
            onOrderSuccessful: orderConfirmation,
            onGoToPreview: goToPreview
          }, null, 8, ["showThankYouMsg", "freeProduct", "contact", "show-modal", "location", "paymentMethods", "locationId", "offerId", "upsellType", "offer", "checkoutSource"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})